import {SxProps} from '@mui/material';
import {Theme} from '@mui/system';
import {AlertVariant} from './types';

export const styles: (variant: AlertVariant) => Record<string, SxProps<Theme>> = (variant) => ({
  root: {
    width: 'calc(100% - 64px)',
    height: 'calc(100% - 24px)',
    position: 'absolute',
    zIndex: 100,
    background:
      variant === 'warn'
        ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FF9800'
        : '#E5EEFA',
    color: variant === 'warn' ? '#C77700' : '#0052CC',
    top: 0,
    left: 0,
    margin: 0,
    padding: '12px 32px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    marginLeft: 2,
    color: variant === 'warn' ? '#C77700' : '#0052CC',
  },
  iconColor: {
    color: variant === 'warn' ? '#C77700' : '#0052CC',
  },
});
