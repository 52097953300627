import {TableViewLayout} from '@components/layouts/TableViewLayout';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {isNullish} from '@front-libs/helpers';
import SvgIcon from '@material-ui/core/SvgIcon';
import {CategoryFormatter} from '@modules/categories/helpers';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {UpdateData, bulkUpdateWholeProducts} from '@modules/hospital_whole_products_bulk_update/api';
import {useWholeProductsQuery} from '@modules/hospital_whole_products_bulk_update/hooks';
import {productClassTypeToClassNameLabel} from '@modules/products/constants';
import {ProductClassType, WholeProductIndex} from '@modules/products/types';
import {TableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {openSnackBar} from '@molecules/SnackBar';
import {Table} from '@molecules/Table';
import {Column, RowAction, SelectionAction} from '@molecules/Table/props';
import {Edit} from '@mui/icons-material';
import {styled} from '@mui/material';
import {useAtomValue, useSetAtom} from 'jotai';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  orderKeyAtom,
  searchNameAtom,
  wholeProductCurrentLayout,
  wholeProductTotalCount,
} from '../../modules/hospital_whole_products_bulk_update/jotai';
import {EditableColumnModal, EditableColumnModalProps} from './Modal/EditableColumnModal';
import {MEIDAI_HIT_4454_COPY_PRODUCY_FLAG} from '@constants/constants';
import {useCopyWholeProduct} from './hooks';

/**
 * 機種一覧ページ機種テーブルボディ
 */
export const WholeProductsListContentBody = () => {
  const setOrder = useSetAtom(orderKeyAtom);
  const {isReadOnly} = useMyRole();
  const nameAtom = useAtomValue(searchNameAtom);
  const {myInfo} = useMyInfo();
  const navigate = useNavigate();
  const query = useWholeProductsQuery(myInfo.hospitalHashId);
  const currentLayout = useAtomValue(wholeProductCurrentLayout);
  const setTotalCount = useSetAtom(wholeProductTotalCount);
  const copyWholeProduct = useCopyWholeProduct({
    hospitalHashId: myInfo.hospitalHashId,
  });

  useEffect(() => {
    if (!query?.data?.totalCount) return;
    setTotalCount(query.data.totalCount);
  }, [query?.data?.totalCount]);

  const handleOrderChange = (columnIndex: number, orderDirection: 'asc' | 'desc') => {
    if (columnIndex === -1) {
      setOrder(null);
      return;
    }

    if (currentLayout) {
      const fieldName = currentLayout[columnIndex].field;

      setOrder({
        fieldId: fieldName,
        direction: orderDirection,
      });
    }
  };

  const handleBulkUpdateProducts = useCallback(async (_e: React.MouseEvent, selectedData: WholeProductIndex[]) => {
    try {
      const res = await dialogHandler.open<EditableColumnModalProps, UpdateData>(EditableColumnModal, {
        selected: selectedData.map((item) => item.hashId),
      });
      if (!res) return;
      await bulkUpdateWholeProducts(
        myInfo.hospitalHashId,
        res,
        selectedData.map((v) => v.hashId)
      );
      await query.refetch();
      openSnackBar('編集が完了しました。');
    } catch (__e) {
      openSnackBar('編集に失敗しました。', 'left', 'bottom', 'error');
    }
  }, []);

  /** 該当データなし */
  const noDataComponent = useMemo(() => {
    if (nameAtom) {
      return (
        <div>
          <FontBoldP>現在の検索条件に一致する機種はありません。</FontBoldP>
          <p>検索条件を変えて、再度検索してみてください。</p>
        </div>
      );
    } else {
      return (
        <div>
          <FontBoldP>機種を登録しましょう。</FontBoldP>
          <p>機種を登録して、ここで機種の情報を管理します。</p>
        </div>
      );
    }
  }, [nameAtom]);

  /**
   * テーブル並び順のシリアライズ
   */
  const serializedTableColumn = useMemo(() => {
    const tableColumn = Object.assign<Column<WholeProductIndex>[], TableLayout[]>([], currentLayout ?? []);
    const map = tableColumn.map<Column<WholeProductIndex>>((item) => {
      if (item.field === 'rootCategory') {
        item.render = ({categories}) => CategoryFormatter.getRootCategory(categories)?.name || '';
      } else if (item.field === 'narrowCategory') {
        item.render = ({categories}) => CategoryFormatter.getNarrowCategory(categories)?.name || '';
      } else if (item.field === 'maker') {
        item.render = ({maker}) => maker?.name || '';
      } else if (item.field === 'isSpecificMaintain') {
        item.render = ({isSpecificMaintain}) =>
          isNullish(isSpecificMaintain) ? '' : isSpecificMaintain ? '該当' : '非該当';
      } else if (item.field === 'className') {
        item.render = ({className}) =>
          (className && productClassTypeToClassNameLabel[className as ProductClassType]) || '';
      } else if (item.field === 'catalogPrice') {
        item.render = ({catalogPrice}) => (isNullish(catalogPrice) ? '' : `${catalogPrice.toLocaleString()}円`);
      }
      item.noBodyWrap = true;

      return item;
    });
    return map;
  }, [currentLayout]);

  const selectionButtons: SelectionAction<WholeProductIndex>[] = useMemo(() => {
    return [
      {
        label: '編集',
        IconComponent: Edit as typeof SvgIcon, // material-ui v4対応
        onClick: handleBulkUpdateProducts,
      },
    ];
  }, []);

  /**
   * 機器を新規登録押下
   * @param {React.MouseEvent} _e - React.MouseEvent
   * @param {WholeProductIndex} data -WholeProductIndex
   */
  const handleClickCreateProduct = useCallback(
    (_e: React.MouseEvent, data: WholeProductIndex) => {
      navigate(`/products/registration/Step2/${data.hashId}`);
    },
    [navigate]
  );

  const handleClickCopyWholeProduct = (_e: React.MouseEvent, data: WholeProductIndex) => {
    copyWholeProduct(data);
  };

  /** マウスオーバーのボタンを定義 */
  const rowAction: RowAction<WholeProductIndex>[] = useMemo(() => {
    const featureVersionActions: RowAction<WholeProductIndex>[] = [
      {
        type: 'button',
        label: '機器を登録',
        onClick: handleClickCreateProduct,
      },
      ...(MEIDAI_HIT_4454_COPY_PRODUCY_FLAG
        ? [
            {
              type: 'button' as const,
              label: '機種を複製して機器を登録',
              onClick: handleClickCopyWholeProduct,
            },
          ]
        : []),
    ];
    const actions: RowAction<WholeProductIndex>[] = !isReadOnly ? featureVersionActions : [];

    return actions;
  }, [handleClickCreateProduct, handleClickCopyWholeProduct]);

  return (
    <TableViewLayout.Body>
      <Table<WholeProductIndex>
        stickyHeader={true}
        columns={serializedTableColumn}
        isLoading={query.isLoading}
        data={query.data?.data ?? []}
        showSelection={!isReadOnly}
        onOrderChange={handleOrderChange}
        noDataComponent={noDataComponent}
        tableSize="small"
        selectionButtons={selectionButtons}
        rowActions={rowAction}
        defaultOrder={{fieldId: 'displayName', direction: 'asc'}}
      />
    </TableViewLayout.Body>
  );
};

const FontBoldP = styled('p')({
  fontWeight: 700,
});
