import React, {memo, useState, useCallback, useMemo, useRef} from 'react';
import {Grid} from '@material-ui/core';
import {FastField, FieldInputProps, FieldMetaProps, FormikErrors, FormikProps} from 'formik';
import {
  CheckboxField as CheckboxFieldType,
  Field as FieldType,
  FormValue,
  NumericField as NumericFieldType,
  PullDownField as PullDownFieldType,
  RadioField as RadioFieldType,
} from '@Apps/InspectionSettings/InspectionEditor/types';
import {BaseField} from '@Apps/InspectionSettings/InspectionEditor/fields/BaseField';
import {CheckboxField} from '@Apps/InspectionSettings/InspectionEditor/fields/CheckboxField';
import {PullDownField} from '@Apps/InspectionSettings/InspectionEditor/fields/PullDownField';
import {NumericField} from '@Apps/InspectionSettings/InspectionEditor/fields/NumericField';
import {SingleLineTextField} from '@Apps/InspectionSettings/InspectionEditor/fields/SingleLineTextField';
import {MultiLineTextField} from '@Apps/InspectionSettings/InspectionEditor/fields/MultiLineTextField';
import {DateField} from '@Apps/InspectionSettings/InspectionEditor/fields/DateField';
import {TimeField} from '@Apps/InspectionSettings/InspectionEditor/fields/TimeField';
import {RadioField} from '@Apps/InspectionSettings/InspectionEditor/fields/RadioField';
import {useFocused} from './hooks';
import {useEffect} from 'react';
import {DraggableProvidedDragHandleProps} from 'react-beautiful-dnd';
import {useAtomValue} from 'jotai';
import {focusedItemAtom} from './states';

const getSettings = (field: FieldType) => {
  // common settings
  const settings = [
    {value: 'showsInspectionPoint', label: '点検箇所', checked: field.settings.showsInspectionPoint},
    {value: 'showsDescription', label: '説明', checked: field.settings.showsDescription},
  ];
  switch (field.type) {
    case 'checkbox':
    case 'pull-down':
    case 'radio':
    case 'numeric':
      settings.push({value: 'showsValidator', label: 'エラー値の設定', checked: field.settings.showsValidator});
      return settings;
    default:
      return settings;
  }
};

const getNewField = (prev: FieldType, newType: FieldType['type']): FieldType => {
  const baseField = {
    name: prev.name,
    required: prev.required,
    inspectionPoint: prev.settings.showsInspectionPoint ? prev.inspectionPoint : null,
    description: prev.settings.showsDescription ? prev.description : null,
  };
  const baseSettings = {
    showsInspectionPoint: prev.settings.showsInspectionPoint,
    showsDescription: prev.settings.showsDescription,
  };

  switch (newType) {
    case 'checkbox':
    case 'pull-down':
    case 'radio': {
      const options = 'options' in prev ? prev.options : [];
      return {
        ...baseField,
        type: newType,
        options: options,
        validators: {
          values: [],
        },
        settings: {
          ...baseSettings,
          showsValidator: true,
        },
      };
    }
    case 'numeric':
      // prev doesn't have unit, validators, and showsValidator
      return {
        ...baseField,
        type: newType,
        unit: '',
        validators: [
          // NOTE: 初期値を入力した場合公開ボタンが押せなくなるため、コメントアウト
          // {
          //   type: null,
          // },
        ],
        settings: {
          ...baseSettings,
          showsValidator: true,
        },
      };
    default:
      return {
        ...baseField,
        type: newType,
        settings: baseSettings,
      };
  }
};

type FieldSelectorProps = {
  field: FieldType;
  errors: FormikErrors<FieldType>;
  focused: boolean;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  onChangeField: (fieldName: string | '', value: any, validate: boolean) => void;
};

const FieldSelector = (props: FieldSelectorProps) => {
  const {field, errors, focused, onChangeField} = props;
  switch (field.type) {
    case 'checkbox':
      return (
        <CheckboxField
          field={field}
          errors={errors as FormikErrors<CheckboxFieldType> & {_options?: string}}
          focused={focused}
          placeholder="選択肢を追加"
          onChangeField={onChangeField}
        />
      );
    case 'pull-down':
      return (
        <PullDownField
          field={field}
          errors={errors as FormikErrors<PullDownFieldType> & {_options?: string}}
          focused={focused}
          placeholder="選択肢を追加"
          onChangeField={onChangeField}
        />
      );
    case 'radio':
      return (
        <RadioField
          field={field}
          errors={errors as FormikErrors<RadioFieldType> & {_options?: string}}
          focused={focused}
          placeholder="選択肢を追加"
          onChangeField={onChangeField}
        />
      );
    case 'numeric':
      return (
        <NumericField
          field={field}
          errors={errors as FormikErrors<NumericFieldType>}
          focused={focused}
          placeholder="数値"
          placeholderForUnit="単位なし"
          onChangeField={onChangeField}
        />
      );
    case 'text':
      return <SingleLineTextField focused={focused} placeholder="記述式テキスト(短文回答)" />;
    case 'multiline-text':
      return <MultiLineTextField focused={focused} placeholder="記述式テキスト(長文回答)" />;
    case 'date':
      return <DateField focused={focused} />;
    case 'time':
      return <TimeField focused={focused} />;
    default:
      return null;
  }
};

type FieldProps = {
  sectionIndex: number;
  fieldIndex: number;
  dragHandlerProps: DraggableProvidedDragHandleProps;
  onClickDuplicate: React.MouseEventHandler<HTMLDivElement>;
  onClickDelete: React.MouseEventHandler<HTMLDivElement>;
};

type FastFieldProps = {
  field: FieldInputProps<FieldType>;
  meta: FieldMetaProps<FieldType>;
  form: FormikProps<FormValue>;
};

type FieldInnerProps = FieldProps & FastFieldProps;

// Hook to return if it's after the second time render
const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  return isMounted;
};

const FieldInner = (props: FieldInnerProps) => {
  const {sectionIndex, fieldIndex, dragHandlerProps, field, form, meta, onClickDuplicate, onClickDelete} = props;
  const settings = useMemo(() => getSettings(field.value), [field]);
  const ref = useRef<HTMLDivElement | null>(null);
  const isMounted = useIsMounted();

  const [focused, onFocused] = useFocused(sectionIndex, fieldIndex);
  const focusedItem = useAtomValue(focusedItemAtom);

  // 項目が描画された時（追加された時）にフォーカスする＆スクロールする
  useEffect(() => {
    if (focused && focusedItem.el === null) {
      ref.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
      if (ref.current !== null) {
        onFocused(ref.current);
      }
    }
  }, [focusedItem.el, onFocused, focused]);

  useEffect(() => {
    // Validate from second render
    if (isMounted && !focused) {
      form.setFieldTouched(field.name, true, true);
    }
  }, [field.name, focused, form, isMounted]);

  const handleClickContainer = useCallback(
    (e) => {
      e.stopPropagation();
      if (ref.current !== null) {
        onFocused(ref.current);
      }
    },
    [onFocused]
  );

  // eslint-disable-next-line no-shadow
  const handleChangeField = useCallback(
    (name: string | '', value: unknown, validate: boolean) => {
      const fieldPath = name !== '' ? `${field.name}.${name}` : field.name;
      form.setFieldValue(fieldPath, value, validate);
    },
    [field.name, form]
  );

  const handleChangeType = useCallback(
    (type: FieldType['type']) => {
      if (type !== field.value.type) {
        handleChangeField('', getNewField(field.value, type), false);
      }
    },
    [field.value, handleChangeField]
  );

  const errors = (meta.error as FormikErrors<FieldType>) ?? {};
  const touched = meta.touched;
  return (
    <Grid ref={ref} container onClick={handleClickContainer}>
      <BaseField
        dragHandlerProps={dragHandlerProps}
        field={field.value}
        errors={errors}
        focused={focused}
        touched={touched}
        settings={settings}
        onChangeType={handleChangeType}
        onChangeField={handleChangeField}
        onClickDuplicate={onClickDuplicate}
        onClickDelete={onClickDelete}>
        <FieldSelector field={field.value} errors={errors} focused={focused} onChangeField={handleChangeField} />
      </BaseField>
    </Grid>
  );
};

export const Field = memo((props: FieldProps) => {
  const {sectionIndex, fieldIndex} = props;
  return (
    <FastField name={`sections[${sectionIndex}].fields[${fieldIndex}]`}>
      {({field, form, meta}: FastFieldProps) => <FieldInner {...props} field={field} meta={meta} form={form} />}
    </FastField>
  );
});
