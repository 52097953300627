import React from 'react';
import {createStyles, Grid, makeStyles, Theme, TextField} from '@material-ui/core';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    dateFieldContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    dateField: {
      width: '100%',
    },
  })
);

type DateFieldProps = {
  focused: boolean;
};

export const DateField: React.FC<DateFieldProps> = (props) => {
  const {focused} = props;
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid className={classes.dateFieldContainer} md={6}>
        <TextField className={classes.dateField} type="date" disabled={focused} variant="standard" />
      </Grid>
    </Grid>
  );
};
