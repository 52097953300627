import React, {useCallback, useMemo} from 'react';
import {FolderOpen, ShowChart, Apps} from '@material-ui/icons';
import {useNavigate} from 'react-router-dom';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {AppMenu} from './AppMenu';
import {makeStyles, createStyles} from '@material-ui/core';
import {useUserPermissions} from '@modules/auth/hooks';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';

export const GeneralMenu: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();

  const menus = useMemo(() => {
    return [
      {icon: <FolderOpen />, label: 'ファイル一覧', value: 'fileList', isPermitted: true},
      {
        icon: <ShowChart />,
        label: '経営ダッシュボード',
        value: 'managementDashboard',
        isPermitted: myInfo?.canAccessMgmtDashboard,
      },
      {icon: <Apps />, label: '病棟ユーザーメニュー', value: 'wardMenus', isPermitted: true},
    ] as const;
  }, [myInfo]);

  type MenuKeys = (typeof menus)[number]['value'];

  const menuLinkMap: Record<MenuKeys, string> = {
    fileList: '/general_menu/file_list',
    managementDashboard: '/general_menu/management_dashboard',
    wardMenus: '/shared/reception_menu',
  };

  const handleMenuClick = useCallback(
    (item: MenuItemType) => {
      const key = item.value as MenuKeys;
      const link = menuLinkMap[key];
      navigate(link);
    },
    [navigate]
  );

  const menuItemList = useMemo(
    () =>
      menus
        .filter((item) => item.isPermitted)
        .map((item) => ({
          label: (
            <>
              {item.icon}
              <span style={{paddingLeft: 8}}>{item.label}</span>
            </>
          ),
          value: item.value,
        })),
    [menus]
  );

  return (
    <PopperMenuButton
      menuItemList={menuItemList}
      onMenuClick={handleMenuClick}
      hiddenArrow
      buttonProps={{variant: 'text', size: 'small', disableElevation: true, className: classes.generalMenuButton}}>
      <AppMenu />
    </PopperMenuButton>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    generalMenuButton: {
      color: '#46484B',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      minWidth: '24px',
    },
  })
);
