import {useParams, useNavigate} from 'react-router-dom';
import {useFetchProductQuery} from '@modules/products/api';
import React, {useEffect, useReducer, useState} from 'react';
import {ModeType, VIEW_MODE, validateInspectionSetting} from './type';
import {useFetchInspectionSettingsQuery} from '@modules/inspection_setting/hooks';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {
  CANCEL_ACTION,
  CHANGE_INSPECTION_ACTION,
  DELETE_ACTION,
  EDIT_ACTION,
  EditWholeProductPlan,
  EditWholeProductPlanAction,
  reducerFunc,
  SET_INITIAL_DATA_ACTION,
  SAVE_ACTION,
  CHANGE_INSPECTION_SETTING_NAME_ACTION,
  CHANGE_INSPECTION_PERIOD_ACTION,
  CHANGE_INSPECTION_TYPE_ACTION,
  SAVE_ADD_ACTION,
} from './utils/reducer';
import {v4 as uuidv4} from 'uuid';
import {
  BulkInsertInspectionSettings,
  bulkInsertInspectionSettings,
  deleteInspectionSetting,
  updateInspectionProductSetting,
} from '@modules/inspection_setting/api';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {openSnackBar} from '@components/molecules/SnackBar';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {InspectionSetting, PeriodUnit} from '@modules/inspection_setting/types';
import {InspectionTypeOptions, InspectionTypeOptionsValues} from '@modules/inspections/enum';
import {InspectionWholeProductPlanDeleteAlertDialog} from '@organisms/InspectionWholeProductPlanDeleteAlertDialog';
import {InspectionWholeProductPlanUpdateAlertDialog} from '@organisms/InspectionWholeProductPlanUpdateAlertDialog';

// FIXME いずれ100件超えたタイミングでページネーション対応する
const INITIAL_PAGE = 0;
const PER_PAGE = 100;

// 点検タイプのソート順を定義 (使用前 中 後 定期 の順)
const inspectionTypeOrder: {[key: string]: number} = {
  pre_use: 0,
  in_use: 1,
  post_use: 2,
  periodic: 3,
};

export const useWholeProductEditPlanList = () => {
  const {wholeProductHashId} = useParams();
  const {data: product} = useFetchProductQuery(wholeProductHashId ?? '');
  const {myInfo} = useMyInfo();
  const {data: inspectionSettings} = useFetchInspectionSettingsQuery(myInfo.hospitalHashId, {
    page: INITIAL_PAGE,
    perPage: PER_PAGE,
    wholeProductHashId: wholeProductHashId,
    showDeleted: false,
  });

  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/inspection_v2/whole_product_plans');
  };

  const sortedInspectionSettings = sortInspectionSettings(inspectionSettings);

  const initialData: EditWholeProductPlan[] = sortedInspectionSettings.map((inspectionSetting) => {
    return {
      ...inspectionSetting,
      uuid: uuidv4(),
      mode: VIEW_MODE,
      inspectionPeriodUnit: 'month',
      beforeInspectionHashId: inspectionSetting.inspectionHashId,
      beforeInspectionName: inspectionSetting.inspectionName,
      beforeInspectionSettingName: inspectionSetting.inspectionSettingName,
    };
  });
  const [editRows, dispatch] = useReducer(reducerFunc, initialData);
  useEffect(() => {
    const updatedRows = inspectionSettings.map((setting) => ({
      ...setting,
      uuid: uuidv4(),
      mode: VIEW_MODE as ModeType,
      inspectionPeriodUnit: 'month',
      beforeInspectionHashId: setting.inspectionHashId,
      beforeInspectionName: setting.inspectionName,
      beforeInspectionSettingName: setting.inspectionSettingName,
    }));
    dispatch({type: SET_INITIAL_DATA_ACTION, payload: updatedRows});
  }, [inspectionSettings]);

  return {product, inspectionSettings, handleBack, editRows, dispatch};
};

type wholeProductEditPlanProps = {
  dispatch: React.Dispatch<EditWholeProductPlanAction>;
  inspectionSetting: EditWholeProductPlan;
  inspectionSettings: EditWholeProductPlan[];
};

export const useWholeProductEditPlan = ({
  dispatch,
  inspectionSetting,
  inspectionSettings,
}: wholeProductEditPlanProps) => {
  const {uuid} = inspectionSetting;
  const {wholeProductHashId} = useParams();
  const {myInfo} = useMyInfo();
  const {isAdmin: canDelete} = useMyRole();

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    // バリデーションを実行し、状態を更新
    const checkValidation = async () => {
      const valid = await validateInspectionSetting(inspectionSetting);
      const isDuplicatedPeriodicInspectionSelected = hasDuplicatePeriodicInspectionHashId(inspectionSettings);
      setIsValid(valid && !isDuplicatedPeriodicInspectionSelected);
    };
    checkValidation();
  }, [inspectionSetting]);

  const handleEditMode = () => {
    dispatch({type: EDIT_ACTION, uuid});
  };
  const handleDelete = async () => {
    try {
      await dialogHandler.open(InspectionWholeProductPlanDeleteAlertDialog, {});
    } catch (_e) {
      return;
    }

    try {
      await deleteInspectionSetting(
        myInfo.hospitalHashId,
        wholeProductHashId ?? '',
        inspectionSetting.inspectionSettingHashId ?? ''
      );
      openSnackBar('点検設定を削除しました', 'left', 'bottom', 'success');
      dispatch({type: DELETE_ACTION, uuid});
    } catch (_e) {
      console.error(_e);
      openSnackBar('点検設定の削除に失敗しました', 'left', 'bottom', 'error');
    }
  };

  const handleSave = async () => {
    // 点検表の変更がある場合は確認のダイアログを表示する
    if (inspectionSetting.beforeInspectionHashId !== inspectionSetting.inspectionHashId) {
      try {
        await dialogHandler.open(InspectionWholeProductPlanUpdateAlertDialog, {});
      } catch (_e) {
        return;
      }
    }

    try {
      await updateInspectionProductSetting(
        myInfo.hospitalHashId,
        wholeProductHashId ?? '',
        inspectionSetting.inspectionSettingHashId ?? '',
        {
          inspectionSettingName: inspectionSetting.inspectionSettingName,
          inspectionHashId: inspectionSetting.inspectionHashId,
        }
      );
      openSnackBar('点検設定を保存しました', 'left', 'bottom', 'success');
    } catch (_e) {
      openSnackBar('点検設定の保存に失敗しました', 'left', 'bottom', 'error');
    } finally {
      // FIXME 保存の場合、hashIDを保存しなおす必要あり
      dispatch({type: SAVE_ACTION, uuid});
    }
  };

  const handleAddSave = async () => {
    try {
      const newInspectionSettings: BulkInsertInspectionSettings = {
        inspectionSettings: [
          {
            inspectionSettingName: inspectionSetting.inspectionSettingName,
            inspectionHashId: inspectionSetting.inspectionHashId,
            inspectionPeriod:
              inspectionSetting.inspectionType === 'periodic'
                ? inspectionSetting.inspectionPeriod ?? undefined
                : undefined,
            inspectionPeriodUnitType:
              inspectionSetting.inspectionType === 'periodic' ? ('month' as PeriodUnit) : undefined,
          },
        ],
      };
      const res = await bulkInsertInspectionSettings(
        myInfo.hospitalHashId,
        wholeProductHashId ?? '',
        newInspectionSettings
      );
      // FIXME 保存の場合、hashIDを保存しなおす必要あり
      // dispatch({type: SAVE_ACTION, uuid, inspectionSettingHashId: res.inspectionSettings[0].inspectionSettingHashId});
      dispatch({
        type: SAVE_ADD_ACTION,
        uuid,
        // TODO 1件のみのため、0番目を取得
        inspectionSettingHashId: res.data.inspectionSettings[0].inspectionSettingHashId,
      });
      openSnackBar('点検設定を保存しました', 'left', 'bottom', 'success');
    } catch (_e) {
      // 失敗時は元の状態に戻す
      dispatch({type: CANCEL_ACTION, uuid});
      openSnackBar('点検設定の保存に失敗しました', 'left', 'bottom', 'error');
    }
  };

  const handleCancel = () => {
    dispatch({type: CANCEL_ACTION, uuid});
  };

  const handleInspectionChange = (inspectionHashId: string, inspectionName: string) => {
    dispatch({type: CHANGE_INSPECTION_ACTION, uuid, inspectionHashId, inspectionName});
  };

  const handleInspectionSettingNameChange = (inspectionSettingName: string) => {
    dispatch({type: CHANGE_INSPECTION_SETTING_NAME_ACTION, uuid, inspectionSettingName});
  };

  const handleInspectionPeriodChange = (inspectionPeriod: number) => {
    dispatch({type: CHANGE_INSPECTION_PERIOD_ACTION, uuid, inspectionPeriod});
  };

  const handleInspectionTypeChange = (inspectionType: string) => {
    dispatch({type: CHANGE_INSPECTION_TYPE_ACTION, uuid, inspectionType});
  };

  // inspectionSettings
  // 自身を対象外にし、点検タイプの一覧を表示する
  const inAvailableInspectionTypes = inspectionSettings
    .filter((item) => item.uuid !== inspectionSetting.uuid)
    .map((item) => item.inspectionType);
  // 定期点検は複数計画作成可能。日常点検は1つのみ作成可能。
  const displayInspectionTypeOptions = InspectionTypeOptions.filter((option) => {
    return (
      option.value === 'periodic' || !inAvailableInspectionTypes.includes(option.value as InspectionTypeOptionsValues)
    );
  });

  return {
    handleEditMode,
    handleDelete,
    handleSave,
    handleAddSave,
    handleCancel,
    handleInspectionChange,
    handleInspectionSettingNameChange,
    handleInspectionPeriodChange,
    handleInspectionTypeChange,
    canDelete,
    isValid,
    displayInspectionTypeOptions,
  };
};

const hasDuplicatePeriodicInspectionHashId = (inspectionSettings: EditWholeProductPlan[]): boolean => {
  const hashIdSet = new Set<string>();
  for (const inspectionSetting of inspectionSettings) {
    // 定期点検表のみ重複をチェック
    if (inspectionSetting.inspectionType !== 'periodic') continue;
    if (hashIdSet.has(inspectionSetting.inspectionHashId)) {
      return true; // 同じinspectionHashIdが見つかった場合
    }
    hashIdSet.add(inspectionSetting.inspectionHashId);
  }
  return false; // 重複がなかった場合
};

const sortInspectionSettings = (inspectionSettings: InspectionSetting[]) => {
  return inspectionSettings.sort((a, b) => {
    const typeOrderComparison = inspectionTypeOrder[a.inspectionType] - inspectionTypeOrder[b.inspectionType];
    if (typeOrderComparison !== 0) {
      return typeOrderComparison;
    }

    // inspectionTypeがperiodicの場合、inspectionPeriodでソート
    if (a.inspectionType === 'periodic' && b.inspectionType === 'periodic') {
      return a.inspectionPeriod - b.inspectionPeriod;
    }

    return 0; // 同じtypeでperiodicでない場合
  });
};
