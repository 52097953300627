import React, {useCallback} from 'react';
import {Box, Button, makeStyles} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';
import {PRODUCT_REGISTRATION_FOOTER_HEIGHT, WEB_CHAT_ICON_WIDTH} from './constants';
import {useAtom, useSetAtom} from 'jotai';
import {
  searchNameAtom,
  selectedCategoryAtom,
  selectedMakerAtom,
  selectedNarrowCategoryAtom,
  selectedProductAtom,
  selectedRootCategoryAtom,
} from './state';
import {isNullish} from '@front-libs/helpers';
import {CreateWholeProduct} from '@modules/products/types';
import {openSnackBar} from '@components/molecules/SnackBar';
import {createWholeProduct, getProducts} from '@modules/products/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';

export const Footer: React.FC = React.memo(() => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  const [selectedProduct, setSelectedProduct] = useAtom(selectedProductAtom);
  const setSearchName = useSetAtom(searchNameAtom);
  const setSelectedMaker = useSetAtom(selectedMakerAtom);
  const setSelectedRootCategory = useSetAtom(selectedRootCategoryAtom);
  const setSelectedNarrowCategory = useSetAtom(selectedNarrowCategoryAtom);
  const setSelectedCategoryCategory = useSetAtom(selectedCategoryAtom);

  const handleClickTop = useCallback(() => {
    setSearchName('');
    setSelectedProduct(null);
    setSelectedMaker(null);
    setSelectedRootCategory(null);
    setSelectedNarrowCategory(null);
    setSelectedCategoryCategory(null);
    navigate('/products');
  }, [
    navigate,
    setSearchName,
    setSelectedCategoryCategory,
    setSelectedMaker,
    setSelectedNarrowCategory,
    setSelectedProduct,
    setSelectedRootCategory,
  ]);

  const handleClick = useCallback(async () => {
    // 院内マスタの場合はそのまま機器登録画面へ
    if (!selectedProduct?.isShared) return navigate(`/products/registration/Step2/${selectedProduct?.hashId}`);
    const rootCategoryHashId = selectedProduct?.categories[0].hashId;
    const narrowCategoryHashId = selectedProduct?.categories[1].hashId;

    if (isNullish(rootCategoryHashId) || isNullish(narrowCategoryHashId)) {
      openSnackBar('機種の登録に失敗しました。');
      return;
    }

    const {data} = await getProducts({originalProductHashId: selectedProduct?.hashId});
    if (data.length === 0) {
      const newProduct: CreateWholeProduct = {
        ...selectedProduct,
        rootCategoryHashId: rootCategoryHashId,
        narrowCategoryHashId: narrowCategoryHashId,
        makerHashID: selectedProduct?.maker?.hashId,
        originalProductHashId: selectedProduct?.hashId,
        thumbnailUrl: selectedProduct?.thumbnailUrl,
        isShared: false,
      };

      const res = await createWholeProduct(myInfo.hospitalHashId, newProduct);

      navigate(`/products/registration/Step2/${res.data.hashId}`);
    } else {
      navigate(`/products/registration/Step2/${data[0].hashId}`);
    }
  }, [myInfo.hospitalHashId, navigate, selectedProduct]);

  return (
    <Box className={classes.container}>
      <Button variant="outlined" color="primary" className={classes.leftButton} onClick={handleClickTop}>
        一覧に戻る
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.rightButton}
        disabled={isNullish(selectedProduct)}
        onClick={handleClick}>
        確定
      </Button>
    </Box>
  );
});

const useStyles = makeStyles(() => ({
  container: {
    height: PRODUCT_REGISTRATION_FOOTER_HEIGHT,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#f0f2f5',
    zIndex: 1000,
  },
  leftButton: {
    margin: '0px 0px 0px 24px',
    width: '118px',
    height: '40px',
    variant: 'outlined',
    color: 'primary',
  },
  rightButton: {
    margin: `0px calc(24px + ${WEB_CHAT_ICON_WIDTH}) 0px 0px`,
    width: '118px',
    height: '40px',
  },
}));
