export const formFields = [
  {type: 'text', label: '大分類', name: 'rootCategory'},
  {type: 'text', label: '小分類', name: 'narrowCategory'},
  {type: 'text', label: 'メーカー名', name: 'maker'},
  {type: 'text', label: '機種名', name: 'displayName'},
  {type: 'text', label: '型式', name: 'name'},
  {type: 'text', label: '承認番号', name: 'approvalNumber'},
  {type: 'text', label: 'JMDNコード', name: 'jmdnCode'},
  {type: 'text', label: 'JANコード', name: 'janCode'},
  {type: 'text', label: 'GTINコード', name: 'newJanCode'},
  {type: 'boolean', label: '特定保守製品', name: 'isSpecificMaintain'},
  {type: 'text', label: 'クラス分類', name: 'className'},
  {type: 'text', label: '定価', name: 'catalogPrice'},
];

// export const defaultValues = {

// }
