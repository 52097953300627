import {HistoryCardProps} from '@Apps/ProductDetail/Content/Cards/HistoryCard';
import {useMonthlyProductHistory} from '@Apps/ProductDetail/hooks';
import {PinIcon} from '@atoms/Icons/pin';
import {useAuth0} from '@auth0/auth0-react';
import {Box, Grid, Theme, Typography, makeStyles} from '@material-ui/core';
import {styled} from '@material-ui/styles';
import {
  createHospitalProductLog,
  deleteHospitalProductLog,
  updateHospitalProductLog,
} from '@modules/hospital_products/api';
import {useFetchHospitalProductLogsQuery} from '@modules/hospital_products/hooks/useHospitalProductLog';
import {HospitalProductDetail} from '@modules/hospital_products/types';
import {UserFormatter} from '@modules/hospital_users/helpers';
import {useHospitalUsers} from '@modules/hospital_users/hooks/useHospitalUsers';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {UserIndex} from '@modules/hospital_users/types';
import {AddCommentIconButton} from '@molecules/Buttons/AddCommentIconButton';
import {PopperSelectBoxButton} from '@molecules/Buttons/PopperSelectBoxButton';
import {InspectionResultCard} from '@molecules/Cards/InspectionResultCard';
import {MoveTargetBuilding} from '@molecules/Cards/MoveTargetBuildingCard';
import {ProductDetailCommentCard} from '@molecules/Cards/ProductDetailCommentCard';
import {RegistrationCard} from '@molecules/Cards/RegistrationCard';
import {RentalCard} from '@molecules/Cards/RentalCard';
import {RepairCommentCard} from '@molecules/Cards/RepairCommentCard';
import {RepairRegistrationCard} from '@molecules/Cards/RepairRegistrationCard';
import {RepairStatusChangeCard} from '@molecules/Cards/RepairStatusChangeCard';
import {ReturnCard} from '@molecules/Cards/ReturnCard';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {ProductDetailCommentDialog} from '@molecules/Dialogs/ProductDetailCommentDialog';
import {openSnackBar} from '@molecules/SnackBar';
import React, {useCallback, useState} from 'react';
import {TimeLineNormalCard} from '@molecules/Cards/TimeLineNormalCard';

const TimeLineBoxContainer = styled(Box)({
  margin: '16px 32px',
});

const TimeLineHeader = styled(Box)({
  width: '100%',
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
});

const TimeLineFilterContainer = styled('div')({});

type Props = {
  hospitalProductDetail: HospitalProductDetail;
};

export const filterProductHistoryOptions = [
  {label: 'コメント', value: 'comment'},
  {label: '修理', value: 'repair'},
  {label: '点検結果', value: 'inspection_result'},
  {label: '貸出・返却', value: 'rental'},
  {label: '転棟', value: 'move_target_building'},
];

const TIMELINE_HEADER_HEIGHT = '121px';

/**
 * PC/タブレット版のタイムライン表示
 * @returns
 */
export const TimeLineTab = ({hospitalProductDetail}: Props) => {
  const {myInfo} = useMyInfo();
  const {isReadOnly} = useMyRole();
  const {getHospitalUser, hospitalUsers} = useHospitalUsers();
  const {user} = useAuth0();

  const [userHashIds, setUserHashIds] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const {monthlyProductHistory} = useMonthlyProductHistory({
    productHashId: hospitalProductDetail.hashId,
    userHashIds,
    selectedOptions,
    hospitalProduct: hospitalProductDetail,
  });
  const hospitalProductLogQuery = useFetchHospitalProductLogsQuery(
    myInfo.hospitalHashId,
    hospitalProductDetail.hashId,
    {
      perPage: 100,
    }
  );

  const [, setOpenCommentDialog] = useState(false);

  const handleClickAddComment = useCallback(async () => {
    setOpenCommentDialog(true);
    try {
      const comment = await dialogHandler.open(ProductDetailCommentDialog, {
        userIndex: user,
      });
      await createHospitalProductLog(myInfo.hospitalHashId, hospitalProductDetail.hashId, {
        description: comment as string,
        logType: 'comment',
      });
      await hospitalProductLogQuery.refetch();
      openSnackBar('コメントを追加しました');
    } finally {
      setOpenCommentDialog(false);
    }
  }, [hospitalProductDetail.hashId, hospitalProductLogQuery, myInfo.hospitalHashId, user]);

  const handleUpdatePin = useCallback(
    async (logHashId: string | undefined, currentPinned: boolean | undefined, description: string | undefined) => {
      if (!logHashId) return;
      try {
        await updateHospitalProductLog(myInfo.hospitalHashId, hospitalProductDetail.hashId, logHashId, {
          description: description ? description : '',
          pinned: !currentPinned,
        });
        await hospitalProductLogQuery.refetch();
        if (currentPinned) {
          openSnackBar('コメントのピンどめを外しました');
        } else {
          openSnackBar('コメントをピンどめしました');
        }
      } catch (_error) {
        openSnackBar('コメントのピンどめに失敗しました', 'left', 'bottom', 'error');
      }
    },
    [hospitalProductDetail.hashId, hospitalProductLogQuery, myInfo.hospitalHashId]
  );

  const handleUpdateComment = useCallback(
    async (logHashId: string | undefined, pinned: boolean | undefined, comment: string) => {
      if (!logHashId) return;
      try {
        await updateHospitalProductLog(myInfo.hospitalHashId, hospitalProductDetail.hashId, logHashId, {
          pinned: pinned ? pinned : false,
          description: comment,
        });
        await hospitalProductLogQuery.refetch();
        openSnackBar('コメントを更新しました');
      } catch (_error) {
        openSnackBar('コメントの更新に失敗しました', 'left', 'bottom', 'error');
      }
    },
    [hospitalProductDetail.hashId, hospitalProductLogQuery, myInfo.hospitalHashId]
  );

  const handleDeleteComment = useCallback(
    async (logHashId: string | undefined) => {
      if (!logHashId) return;

      try {
        const ok = await dialogHandler
          .open(AlertDialog, {
            title: 'コメントを削除しますか？',
            content: 'コメントを削除しようとしています。\n\nこのアクションは元に戻せません。',
            positiveButtonLabel: 'コメントを削除',
          })
          .then(() => true)
          .catch(() => false);

        if (!ok) {
          return;
        }

        await deleteHospitalProductLog(myInfo.hospitalHashId, hospitalProductDetail.hashId, logHashId);
        await hospitalProductLogQuery.refetch();
        openSnackBar('コメントを削除しました');
      } catch (_error) {
        openSnackBar('コメントの削除に失敗しました', 'left', 'bottom', 'error');
      }
    },
    [hospitalProductDetail.hashId, hospitalProductLogQuery, myInfo.hospitalHashId]
  );

  return (
    <TimeLineBoxContainer>
      <TimeLineHeader>
        {!isReadOnly && <AddCommentIconButton onAddComment={handleClickAddComment} />}
        <TimeLineFilterContainer>
          <PopperSelectBoxButton
            buttonLabel={'すべてのユーザー'}
            options={UserFormatter.getOptions(hospitalUsers, {withAlias: true, withSubLabel: true})}
            isMulti={true}
            onChange={(values) => setUserHashIds(values?.map((item) => item.value) || [])}
            searchable={true}
          />
          <PopperSelectBoxButton
            buttonLabel={'詳細を絞り込み'}
            options={filterProductHistoryOptions}
            isMulti={true}
            allowSelectAll={true}
            onChange={(values) => setSelectedOptions(values?.map((item) => item.value) || [])}
          />
        </TimeLineFilterContainer>
      </TimeLineHeader>
      <TimeLineContent
        monthlyProductHistory={monthlyProductHistory}
        getHospitalUser={getHospitalUser}
        handleDeleteComment={handleDeleteComment}
        handleUpdateComment={handleUpdateComment}
        handleUpdatePin={handleUpdatePin}
      />
    </TimeLineBoxContainer>
  );
};

type TimeLineContentProps = {
  monthlyProductHistory: {
    section: string;
    productHistories: HistoryCardProps[];
  }[];
  getHospitalUser: (hashId: string) => UserIndex;
  handleDeleteComment: (logHashId: string | undefined) => void;
  handleUpdateComment: (logHashId: string | undefined, pinned: boolean | undefined, comment: string) => void;
  handleUpdatePin: (logHashId: string | undefined, pinned: boolean | undefined, comment: string | undefined) => void;
};

const OuterSectionLabel = styled(Typography)({
  fontSize: '12px',
  color: '#65676B',
  display: 'contents',
});

// 調整用の長さ(HITOTSUのヘッダーメニュー、ナビゲーション、詳細のヘッダー、補足情報のヘッダー)
const OTHER_ADJUST_HEIGHT = '144px';

const RootGrid = styled(Grid)({
  overflow: 'auto',
  maxHeight: `calc(100vh - ${OTHER_ADJUST_HEIGHT} - ${TIMELINE_HEADER_HEIGHT})`,
});

const TimeLineContent = (props: TimeLineContentProps) => {
  const {monthlyProductHistory, getHospitalUser, handleDeleteComment, handleUpdateComment, handleUpdatePin} = props;
  const classes = useStyles();
  const {isAdmin, isReadOnly} = useMyRole();

  const PINNED_SECTION_LABEL = 'ピンどめしたコメント';
  return (
    <RootGrid container>
      {monthlyProductHistory.map((outer, outerIdx) => (
        <React.Fragment key={outer.section + String(outerIdx)}>
          <OuterSectionLabel>
            {outer.section === PINNED_SECTION_LABEL && (
              <PinIcon style={{width: '18px', height: '18px', color: '#65676B'}} />
            )}
            {outer.section}
          </OuterSectionLabel>
          {outer.productHistories.map((innerItem, innerIdx) => (
            <Grid
              item
              className={
                innerItem.isPinnedArea
                  ? classes.pinnedAreaHistoryContainer
                  : innerItem.pinned
                    ? classes.pinnedHistoryContainer
                    : classes.historyContainer
              }
              key={innerItem.logHashId + String(innerIdx)}>
              {innerItem.cardType === 'rental' && (
                <RentalCard
                  title={innerItem.title}
                  user={innerItem.user ? getHospitalUser(innerItem.user.hashId) : innerItem.user}
                  displayDate={innerItem.displayDate}
                  contentSlot={innerItem.contentSlot}
                />
              )}
              {innerItem.cardType === 'return' && (
                <ReturnCard
                  title={innerItem.title}
                  user={innerItem.user ? getHospitalUser(innerItem.user.hashId) : innerItem.user}
                  displayDate={innerItem.displayDate}
                  contentSlot={innerItem.contentSlot}
                />
              )}
              {innerItem.cardType === 'inspection_result' && (
                <InspectionResultCard
                  title={innerItem.title}
                  displayDate={innerItem.displayDate}
                  contentSlot={innerItem.contentSlot}
                />
              )}
              {innerItem.cardType === 'comment' && (
                <ProductDetailCommentCard
                  pinned={innerItem.pinned}
                  {...innerItem}
                  isAdmin={isAdmin}
                  isReadOnly={isReadOnly}
                  onDeleteComment={() => handleDeleteComment(innerItem.logHashId)}
                  onUpdateComment={(comment) => handleUpdateComment(innerItem.logHashId, innerItem.pinned, comment)}
                  onUpdatePinned={() => handleUpdatePin(innerItem.logHashId, innerItem.pinned, innerItem.description)}
                />
              )}
              {innerItem.cardType === 'registration' && (
                <RegistrationCard displayDate={innerItem.displayDate} hospitalProduct={innerItem.hospitalProduct} />
              )}
              {innerItem.cardType === 'repair_comment' && (
                <RepairCommentCard
                  faultRepairHashId={innerItem.faultRepairHashId}
                  displayDate={innerItem.displayDate}
                  comment={innerItem.description}
                  createdUser={innerItem.user}
                />
              )}
              {innerItem.cardType === 'repair_registration' && (
                <RepairRegistrationCard
                  user={innerItem.user}
                  faultRepairHashId={innerItem.faultRepairHashId}
                  displayDate={innerItem.displayDate}
                  repairRegistrationNumber={innerItem.repairRegistrationNumber}
                />
              )}
              {innerItem.cardType === 'repair_status_change' && (
                <RepairStatusChangeCard
                  faultRepairHashId={innerItem.faultRepairHashId}
                  displayDate={innerItem.displayDate}
                  fromStatusName={innerItem.contentSlot?.find((item) => item.label === 'fromStatusName')?.value ?? ''}
                  toStatusName={innerItem.contentSlot?.find((item) => item.label === 'toStatusName')?.value ?? ''}
                  executeUser={innerItem.user}
                />
              )}
              {innerItem.cardType === 'move_target_building' && (
                <MoveTargetBuilding
                  title={innerItem.title}
                  user={innerItem.user}
                  displayDate={innerItem.displayDate}
                  contentSlot={innerItem.contentSlot}
                />
              )}
              {(innerItem.cardType === 'fault_reception' || innerItem.cardType === 'fault_reception_comment') && (
                <TimeLineNormalCard
                  title={innerItem.title}
                  icon={innerItem.icon}
                  displayDate={innerItem.displayDate}
                  contentSlot={innerItem.contentSlot}
                />
              )}
            </Grid>
          ))}
        </React.Fragment>
      ))}
    </RootGrid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  actionContainer: {
    paddingTop: '24px',
    fontSize: theme.typography.fontSize,
  },
  action: {
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  pinnedAreaHistoryContainer: {
    width: '100%',
    fontSize: theme.typography.fontSize,
    marginTop: '8px',
    paddingRight: '24px',
    border: '1px solid #C6CBD4',
    borderRadius: '5px',
  },
  historyContainer: {
    paddingTop: '16px',
    fontSize: theme.typography.fontSize,
    width: '100%',
    paddingRight: '24px',
    borderBottom: '1px solid #C6CBD4',
  },
  pinnedHistoryContainer: {
    paddingTop: '16px',
    fontSize: theme.typography.fontSize,
    width: '100%',
    paddingRight: '24px',
    borderBottom: '1px solid #C6CBD4',
    backgroundColor: '#F2F6FC',
  },
  addCommentBtn: {
    backgroundColor: '#323F4E',
    borderRadius: '2px',
    padding: '8px 22px',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#323F4E',
    },
  },
  boldFont: {
    fontWeight: 'bold',
  },
  link: {
    color: theme.palette.primary.dark,
    textDecoration: 'none',
  },
  timelineContent: {
    height: 'calc(100vh - 88px)', // ヘッダーや他のUI要素の高さに応じて調整
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));
