import React, {useCallback, useMemo, useState} from 'react';
import {Button, Grid, TextField, Theme, styled} from '@material-ui/core';
import {Pagination} from '@material-ui/lab';
import {Search} from '@material-ui/icons';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useDebounceState} from '@front-libs/core';
import {Column, RowAction} from '@molecules/Table/props';
import {Table} from '@molecules/Table';
import {openSnackBar} from '@molecules/SnackBar';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {TableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {HospitalDealerNameEditDialog} from './Dialogs/HospitalDealerNameEditDialog';
import {HospitalDealer} from '@modules/hospital_products/types';
import {
  HospitalDealerParams,
  updateHospitalDealer,
  deleteHospitalDealer,
  createHospitalDealer,
  getHospitalDealer,
} from '@modules/hospital_dealer/api';
import {useQuery} from 'react-query';

const StyledTextField = styled(TextField)(({theme}: {theme: Theme}) => ({
  backgroundColor: theme.palette.common.white,
  fontSize: '14px',
}));

const GridTableContainer = styled(Grid)({
  '& .MuiPaper-root': {
    maxHeight: 'calc(100vh - 400px)',
  },
  '& .MuiGrid-container ': {
    color: '#172B4D',
    fontWeight: 'normal',
  },
});

const StyledMarginGrid = styled(Grid)({marginBottom: '24px'});
const StyledFontBold = styled('p')({fontWeight: 'bold'});
const noDataComponent = (
  <div>
    <StyledFontBold>担当代理店が登録されていません。</StyledFontBold>
    <p>右上のボタンから担当代理店を登録できます。</p>
  </div>
);

type SearchBoxProps = {
  label: string;
  searchName: string | null;
  setSearchName: (newState: string | null) => void;
};

/**
 * 検索ボックスを表示
 */
const SearchBox = ({label, searchName, setSearchName}: SearchBoxProps) => {
  return (
    <StyledTextField
      label={label}
      variant="outlined"
      fullWidth
      size="small"
      InputProps={{
        endAdornment: <Search />,
      }}
      defaultValue={searchName ?? ''}
      onChange={(e) => {
        setSearchName(e.target.value);
      }}
    />
  );
};

/**
 * 担当代理店テーブル表示
 * @return
 */
export const HospitalDealerTable = () => {
  const [page, setPage] = useState<number>(1);
  const {myInfo} = useMyInfo();
  const [orderKey, setOrderKey] = useState<string | null>(null);
  const [searchName, setSearchName] = useDebounceState<string | null>(null, 500);
  const pageSize = 20;

  const hospitalDealerParams = useMemo(() => {
    const params: HospitalDealerParams = {
      page: page - 1,
      perPage: pageSize,
      order: orderKey ?? undefined,
    };
    if (searchName) params.name = searchName;
    return params;
  }, [orderKey, page, searchName]);

  const {data, refetch, isLoading} = useQuery(['getHospitalDealer', myInfo.hospitalHashId, hospitalDealerParams], () =>
    getHospitalDealer(myInfo.hospitalHashId, hospitalDealerParams)
  );

  /** ページネーション */
  const handleChangePage = useCallback((event: React.ChangeEvent<unknown>, pageNam: number) => {
    setPage(pageNam);
  }, []);

  /** ページ数 */
  const totalPage = useMemo(() => {
    if (!data) return 1;
    return data.page;
  }, [data]);

  /** 新規追加 */
  const handleClickRegistration = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      const editDialog = await dialogHandler.open(HospitalDealerNameEditDialog, {
        mode: 'create',
      });

      if (!editDialog || !editDialog.name) return;
      try {
        await createHospitalDealer(myInfo.hospitalHashId, {...hospitalDealerParams, name: editDialog.name});
        openSnackBar('担当代理店を更新しました。');
        refetch();
      } catch (_err) {
        openSnackBar('担当代理店の更新に失敗しました。', 'left', 'bottom', 'error');
      }
    },
    [hospitalDealerParams, myInfo.hospitalHashId, refetch]
  );
  /** 名前編集 */
  const handleClickEdit = useCallback(
    async (e: React.MouseEvent, hospitalDealer: HospitalDealer) => {
      e.stopPropagation();
      const editDialog = await dialogHandler.open(HospitalDealerNameEditDialog, {
        hospitalDealer: hospitalDealer,
        mode: 'edit',
      });

      if (!editDialog || !editDialog.name) return;
      try {
        await updateHospitalDealer(myInfo.hospitalHashId, hospitalDealer.hashId, {
          ...hospitalDealerParams,
          name: editDialog.name,
        });
        openSnackBar('担当代理店を更新しました。');
        refetch();
      } catch (_err) {
        openSnackBar('担当代理店の更新に失敗しました。', 'left', 'bottom', 'error');
      }
    },
    [hospitalDealerParams, myInfo.hospitalHashId, refetch]
  );

  /** 削除処理 */
  const handleClickDelete = useCallback(
    async (e: React.MouseEvent, hospitalDealer: HospitalDealer) => {
      e.stopPropagation();
      const name = hospitalDealer.name;
      await dialogHandler.open(AlertDialog, {
        title: `担当代理店「${name}」を削除しますか？`,
        content:
          '担当代理店を削除しようとしています。\n機器に代理店が設定されている場合、空白に戻ります。\n\nこの操作はもとに戻せません。',
        positiveButtonLabel: '削除',
      });

      try {
        await deleteHospitalDealer(myInfo.hospitalHashId, hospitalDealer.hashId, hospitalDealerParams);
        openSnackBar(`担当代理店「${name}」を削除しました。`);
        refetch();
      } catch (_err) {
        openSnackBar(`担当代理店「${name}」の削除に失敗しました。`, 'left', 'bottom', 'error');
      }
    },
    [hospitalDealerParams, myInfo.hospitalHashId, refetch]
  );

  /** 並び順変更 */
  const handleOrderChange = (columnIndex: number, orderDirection: 'asc' | 'desc') => {
    if (columnIndex === -1) {
      setOrderKey(null);
    } else {
      setOrderKey(`${orderDirection === 'desc' ? '-' : ''}${String(serializedTableColumn[columnIndex].field)}`);
    }
    refetch();
  };

  /** テーブルカラム */
  const serializedTableColumn = useMemo(() => {
    const currentTableLayout: TableLayout[] = [
      {
        title: '担当代理店名',
        field: 'name',
      },
    ];
    const tableColumn = Object.assign<Column<HospitalDealer>[], TableLayout[]>([], currentTableLayout);

    return tableColumn.map<Column<HospitalDealer>>((item) => {
      item.render = (hospitalDealer) => {
        return <Grid container>{`${hospitalDealer.name}`}</Grid>;
      };

      item.noBodyWrap = false;
      return item;
    });
  }, []);
  const rowAction: RowAction<HospitalDealer>[] = useMemo(
    () => [
      {
        type: 'button',
        label: '編集',
        onClick: handleClickEdit,
      },
      {
        type: 'button',
        label: '削除',
        onClick: handleClickDelete,
      },
    ],
    [handleClickEdit, handleClickDelete]
  );

  return (
    <>
      <StyledMarginGrid container justifyContent="space-between">
        <Grid item sm={4}>
          <SearchBox label="担当代理店を検索" searchName={searchName} setSearchName={setSearchName} />
        </Grid>
        <Grid item>
          <Button variant={'contained'} color={'primary'} onClick={handleClickRegistration}>
            担当代理店を登録
          </Button>
        </Grid>
      </StyledMarginGrid>

      <GridTableContainer container>
        <StyledMarginGrid container>
          <Table<HospitalDealer>
            columns={serializedTableColumn}
            isLoading={isLoading}
            data={data ? data.data : []}
            rowActions={rowAction}
            showSelection={false}
            onOrderChange={handleOrderChange}
            page={page}
            stickyHeader={true}
            noDataComponent={noDataComponent}
          />
        </StyledMarginGrid>
        {data?.data && (
          <Grid container justifyContent={'center'}>
            <Pagination page={page} count={totalPage} color={'primary'} shape="rounded" onChange={handleChangePage} />
          </Grid>
        )}
      </GridTableContainer>
    </>
  );
};
