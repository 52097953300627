import React from 'react';
import {createStyles, Grid, makeStyles, Theme, TextField} from '@material-ui/core';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    textFieldContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    textField: {
      width: '100%',
    },
  })
);

type MultiLineTextFieldProps = {
  focused: boolean;
  placeholder: string;
  rows?: number;
};

export const MultiLineTextField: React.FC<MultiLineTextFieldProps> = (props) => {
  const {focused, placeholder, rows = 3} = props;
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid className={classes.textFieldContainer} md={6}>
        <TextField
          className={classes.textField}
          multiline
          minRows={rows}
          disabled={focused}
          variant="standard"
          placeholder={placeholder}
        />
      </Grid>
    </Grid>
  );
};
