import {theme} from '@atoms/theme';

// NOTE: 汎用項目の単一選択のFeatureFlag
export const FEATURE_NOTE_SETTING_TYPE_SELECT_FLAG = import.meta.env.VITE_FEATURE_NOTE_SETTING_TYPE_SELECT === 'true';

// NOTE: 機種詳細画面のFeatureFlag
export const MEIDAI_HIT_4742_WHOLE_PRODUCT_DETAIL_FLAG =
  import.meta.env.VITE_MEIDAI_HIT_4742_WHOLE_PRODUCT_DETAIL === 'true';

// NOTE: 点検マニュアル機能のFeatureFlag
export const MEIDAI_HIT_4585_UPLOAD_INSPECTION_MANUAL =
  import.meta.env.VITE_MEIDAI_HIT_4585_UPLOAD_INSPECTION_MANUAL === 'true';

// NOTE: 機種複製登録機能のFeatureFlag
export const MEIDAI_HIT_4454_COPY_PRODUCY_FLAG = import.meta.env.VITE_MEIDAI_HIT_4454_COPY_PRODUCY_FLAG === 'true';

const productStatusType = [
  {
    label: '待機中',
    value: 'ready',
    color: '#3C9E58',
    searchAlias: ['たいきちゅう'],
  },
  {
    label: '貸出中',
    value: 'working',
    color: '#C7243A',
    searchAlias: ['かしだしちゅう'],
  },
  {
    label: '点検待ち',
    value: 'uninspected',
    color: '#826149',
    searchAlias: ['てんけんまち'],
  },
  {
    label: '修理中',
    value: 'repairing',
    color: theme.palette.primary.dark,
    searchAlias: ['しゅうりちゅう'],
  },
  {
    label: '廃棄済み',
    value: 'disabled',
    color: '#727272',
    searchAlias: ['はいきずみ'],
  },
];

export const productStatusOptions = productStatusType.map((item) => ({
  value: item.value,
  label: item.label,
  searchAlias: item.searchAlias,
}));

export const productStatusOptionsWODisabled = productStatusOptions.filter((item) => item.value !== 'disabled');

export const inspectionStatusType = {
  available: {
    label: '利用中',
    color: '#3C9E58',
  },
  draft: {
    label: '下書き',
    color: '#727272',
  },
};
