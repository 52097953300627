import React, {useCallback, useMemo} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
  IconButton,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {Form, Formik, useFormikContext} from 'formik';
import {TextField} from '@molecules/Formik/fields';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import {yup} from '@front-libs/core';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 32px 40px',
  },
  title: {
    color: '#172B4D',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  content: {
    padding: '0px 32px',
  },
  fields: {
    '& > :not(:first-child)': {
      marginTop: '24px',
    },
  },
  inputDate: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  actions: {
    padding: '24px 32px 32px',
  },
}));

type StartDateFormProps = {
  open: boolean;
  onClose: React.MouseEventHandler;
};

type StartDateFormValue = {
  startDate: string;
};

const StartDateDialogForm: React.FC<StartDateFormProps> = (props) => {
  const {open, onClose} = props;
  const classes = useStyles();
  const {submitForm, isValid} = useFormikContext<StartDateFormValue>();

  const handleSubmit = useCallback(() => {
    submitForm();
  }, [submitForm]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography className={classes.title}>点検開始日を一括設定</Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container className={classes.fields} direction="column">
          <Grid>
            <label>点検開始日</label>
            <TextField type="date" name="startDate" size="small" fullWidth />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!isValid}>
          設定
        </Button>
        <Button variant="text" onClick={onClose}>
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export type BulkUpdateStartDateDialogResult = {
  startDate: string;
};

export const BulkUpdateStartDateDialog: React.FC<DialogProps<StartDateFormValue, BulkUpdateStartDateDialogResult>> = (
  props
) => {
  const {open} = props;

  const validationSchema = useMemo(() => yup.object({startDate: yup.string().required()}), []);

  const handleSubmit = useCallback(
    async (data: StartDateFormValue) => {
      props.actions.resolve(data);
    },
    [props.actions]
  );

  const handleClose = useCallback(
    (_e: React.MouseEvent) => {
      props.actions.reject();
    },
    [props.actions]
  );

  return (
    <Formik
      initialValues={{startDate: ''}}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      isInitialValid={false}>
      <Form>
        <StartDateDialogForm open={open} onClose={handleClose} />
      </Form>
    </Formik>
  );
};
