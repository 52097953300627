import React, {useState, useCallback, useEffect} from 'react';
import {useAtom} from 'jotai';
import {Auth0Provider, useAuth0} from '@auth0/auth0-react';
import {axios} from '@front-libs/core';
import {useAsync} from 'react-use';
import {useAsyncEffect} from '@front-libs/core';
import {useOnlyOnce} from '@front-libs/core';
import {accessToken as accessTokenState, auth0CacheAtom} from '../jotai';
import {Loading} from '@organisms/Loading';
import {useAtomValue} from 'jotai';
import {CHANGE_CURRENT_USER_EVENT, CHANGE_USERS_EVENT, MultipleAuth0Cache} from '../auth0-cache';
import {Auth0MultiSignInContext} from '../context';
import {User} from '@auth0/auth0-react';
import {MODE_KEY, MODE_NEW_SIGNIN} from '../consts';

export const withAuth0 = <P extends Record<string, unknown>>(Component: React.ComponentType<P>) => {
  // NOTE:関数コンポーネントのPropsがchildrenプロパティを含むためPropsWithChildrenを定義
  const WithAuth0Component: React.FC<React.PropsWithChildren<P>> = (props) => {
    return (
      <Auth0Provider
        domain={import.meta.env.VITE_AUTH0_DOMAIN as string}
        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID as string}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        audience={import.meta.env.VITE_AUTH0_AUDIENCE as string}
        redirectUri={window.location.origin}>
        <Auth0Authentication>
          <Component {...props} />
        </Auth0Authentication>
      </Auth0Provider>
    );
  };
  return WithAuth0Component;
};

export const withAuth0MultipleSignIn =
  <P extends Record<string, unknown>>(Component: React.ComponentType<P>) =>
  (props: P) => {
    const cache = useAtomValue(auth0CacheAtom);
    return (
      <Auth0Provider
        domain={import.meta.env.VITE_AUTH0_DOMAIN as string}
        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID as string}
        useRefreshTokens={true}
        audience={import.meta.env.VITE_AUTH0_AUDIENCE as string}
        redirectUri={window.location.origin}
        cache={cache}>
        <Auth0MultipleAuthentication cache={cache}>
          <Component {...props} />
        </Auth0MultipleAuthentication>
      </Auth0Provider>
    );
  };

// Auth0の設定より短い方が好ましい
const REFRESH_INTERVAL_TIME = 1000 * 60 * 5;

const Auth0Authentication: React.FC = ({children}) => {
  const {isAuthenticated, loginWithRedirect, isLoading, getAccessTokenSilently, logout} = useAuth0();
  const [innerLoading, setInnerLoading] = useState(true);
  const [accessToken, setAccessToken] = useState<null | string>(null);
  const [, setTokenAtom] = useAtom(accessTokenState);

  const updateToken = useCallback(async () => {
    try {
      setAccessToken(await getAccessTokenSilently());
    } catch (_error) {
      logout({returnTo: window.location.origin});
    }
  }, [logout, getAccessTokenSilently]);

  useAsyncEffect(async () => {
    if (!isLoading && !isAuthenticated) await loginWithRedirect();
  }, [isLoading, isAuthenticated]);

  useOnlyOnce(async () => {
    await updateToken();

    setInterval(async () => {
      await updateToken();
    }, REFRESH_INTERVAL_TIME);
  }, isAuthenticated);

  useAsync(async () => {
    if (!isAuthenticated) return;

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    setTokenAtom(accessToken);
    setInnerLoading(false);
  }, [accessToken]);

  if (isLoading || innerLoading) return <Loading />;
  // React.Fragment消せないのでdisable
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{isAuthenticated && children}</>;
};

const Auth0MultipleAuthentication: React.FC<{cache: MultipleAuth0Cache}> = ({children, cache}) => {
  const {user, isAuthenticated, loginWithRedirect, isLoading, getAccessTokenSilently, logout} = useAuth0();
  const [innerLoading, setInnerLoading] = useState(true);
  const [accessToken, setAccessToken] = useState<null | string>(null);
  const [, setTokenAtom] = useAtom(accessTokenState);
  const [initialized, setInitialized] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | null>(cache.getCurrentUser());
  const [users, setUsers] = useState<User[]>(cache.getUsers());

  // 現在のサインイン情報を維持したまま、新しいユーザにサインインする
  const signIn = useCallback(async () => {
    cache.clearAuth0Caches(true);
    logout({returnTo: window.location.origin + `?${MODE_KEY}=${MODE_NEW_SIGNIN}`});
  }, [cache, logout]);

  // 現在選択しているユーザからサインアウトする
  // サインアウト後にいずれのユーザもログインしていない場合はログイン画面に遷移する
  const signOut = useCallback(async () => {
    cache.signOutCurrentUser();
    if (cache.isLoggedIn()) {
      await updateToken();
    } else {
      cache.removeCurrentUserSub();
      logout({returnTo: window.location.origin});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout]);

  // 現在サインインしているユーザの中から切り替える
  const switchUser = useCallback(
    async (userSub: string) => {
      try {
        cache.switchUser(userSub);
        setAccessToken(await getAccessTokenSilently());
      } catch (_error) {
        // トークン更新に失敗したらサインアウトする
        cache.signOutCurrentUser();
        await signIn();
      }
    },
    [cache, getAccessTokenSilently, signIn]
  );

  // 現在選択しているユーザのトークンをリフレッシュする
  // 失敗した場合はそのユーザからサインアウトする
  const updateToken = useCallback(async () => {
    try {
      setAccessToken(await getAccessTokenSilently());
    } catch (_error) {
      console.warn('failed to update token, signout');
      await signOut();
    }
  }, [getAccessTokenSilently, signOut]);

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const onChangeCurrentUser = (user: User) => {
      setCurrentUser(user);
    };
    // eslint-disable-next-line no-shadow
    const onChangeUsers = (users: User[]) => {
      setUsers(users);
    };

    cache.on(CHANGE_CURRENT_USER_EVENT, onChangeCurrentUser);
    cache.on(CHANGE_USERS_EVENT, onChangeUsers);

    return () => {
      cache.off(CHANGE_CURRENT_USER_EVENT, onChangeCurrentUser);
      cache.off(CHANGE_USERS_EVENT, onChangeUsers);
    };
  }, [cache]);

  // 初期化が完了したにも関わらずログインしていない場合はサインイン画面に遷移する
  useAsyncEffect(async () => {
    if (!initialized && !isLoading && !isAuthenticated) {
      await loginWithRedirect();
    }
  }, [isLoading, isAuthenticated]);

  // トークンの更新
  useOnlyOnce(async () => {
    await updateToken();

    setInterval(async () => {
      await updateToken();
    }, REFRESH_INTERVAL_TIME);
  }, isAuthenticated);

  // 初期signInあとにユーザをcacheに保存する
  useAsyncEffect(async () => {
    if (!initialized && isAuthenticated && user) {
      cache.addCurrentUser(user);
      setInitialized(true);
    }
  }, [isAuthenticated, user?.sub]);

  // トークン更新後にAxiosにトークンを設定する
  useAsyncEffect(async () => {
    if (!isAuthenticated) return;

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    setTokenAtom(accessToken);
    setInnerLoading(false);
  }, [accessToken]);

  if (isLoading || innerLoading) return <Loading />;

  return (
    <Auth0MultiSignInContext.Provider
      value={{
        currentUser: currentUser,
        users: users,
        signIn: signIn,
        switchUser: switchUser,
        signOut: signOut,
      }}>
      {isAuthenticated && children}
    </Auth0MultiSignInContext.Provider>
  );
};
