import React, {memo, useCallback} from 'react';
import {Box, Typography, styled} from '@material-ui/core';
import {NormalCard} from './NormalCard';
import {CommentCard} from '@Apps/FaultReceptions/FaultReceptionList/CommentCard';
import {HistoryCardProps} from '@Apps/ProductDetail/Content/Cards/HistoryCard';
import {HistorySection} from './type';
import {openSnackBar} from '@components/molecules/SnackBar';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {deleteFaultReceptionLog, updateFaultReceptionLog} from '@modules/fault_receptions/api/faultReceptionApi';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {QueryObserverResult} from 'react-query';
import {FaultReceptionLogsResponse} from 'src/modules/fault_receptions/types';

const ContainerBox = styled(Box)(() => ({
  // 168px はヘッダーと内部のPadding・Marginの値の総和
  height: ' calc(100% - 168px)',
  margin: '8px 0px',
}));

const ScrollBox = styled(Box)(() => ({
  overflowX: 'hidden',
  overflowY: 'auto',
  height: '100%',
}));

type CardRendererProps = {
  innerItem: HistoryCardProps;
  isReadOnly: boolean;
  isAdmin: boolean;
  /**
   * コメントが更新された時に呼び出される関数
   * @param comment - 更新されたコメント。
   */
  onUpdateComment: (comment: string) => void;
  /**
   * ピンどめが更新された時に呼び出される関数
   */
  onUpdatePinned: () => void;

  /**
   * コメントが削除された時に呼び出される関数
   */
  onDeleteComment: () => void;
};

/**
 * LogTypeに合わせたカードを返す
 */
const HistoryCard = memo(
  ({innerItem, isReadOnly, isAdmin, onUpdateComment, onUpdatePinned, onDeleteComment}: CardRendererProps) => {
    switch (innerItem.cardType) {
      case 'registration':
        return (
          <NormalCard
            title={innerItem.title}
            icon={innerItem.icon}
            displayDate={innerItem.displayDate}
            contentSlot={innerItem.contentSlot}
            repairRegistrationNumber={innerItem.repairRegistrationNumber}
          />
        );
      case 'fault_reception':
        return (
          <NormalCard
            title={innerItem.title}
            icon={innerItem.icon}
            displayDate={innerItem.displayDate}
            contentSlot={innerItem.contentSlot}
          />
        );
      case 'fault_reception_comment':
        return (
          <CommentCard
            pinned={innerItem.pinned}
            displayDate={innerItem.displayDate}
            description={innerItem.description}
            isReadOnly={isReadOnly}
            user={innerItem.user}
            isArchived={innerItem.isArchived}
            archivedBy={innerItem.archivedBy}
            updatedAt={innerItem.updatedAt}
            isAdmin={isAdmin}
            onUpdateComment={onUpdateComment}
            onUpdatePinned={onUpdatePinned}
            onDeleteComment={onDeleteComment}
          />
        );
      default:
        return null;
    }
  }
);

type Props = {
  monthlyProductHistory: HistorySection[];
  refetch: () => Promise<QueryObserverResult<FaultReceptionLogsResponse, unknown>>;
};

/**
 * スクロール可能な対応履歴内容を表示するコンポーネント
 * @param props.faultReceptionLogsData
 */
export const InnerFaultReceptionCommentList = memo(({monthlyProductHistory, refetch}: Props) => {
  const {myInfo} = useMyInfo();
  const {isReadOnly, isAdmin} = useMyRole();
  const handleUpdatePin = useCallback(
    async (
      faultReceptionHashId: string | undefined,
      logHashId: string | undefined,
      currentPinned: boolean | undefined,
      description: string | undefined
    ) => {
      if (!logHashId || !faultReceptionHashId) return;
      try {
        await updateFaultReceptionLog(myInfo.hospitalHashId, faultReceptionHashId, logHashId, {
          pinned: !currentPinned,
          // NOTE: API側がdescriptionを必須にしているため、undefinedの場合は空文字を入れている。
          description: description || '',
        });
        await refetch();
        if (currentPinned) {
          openSnackBar('コメントのピンどめを外しました');
        } else {
          openSnackBar('コメントをピンどめしました');
        }
      } catch (_error) {
        openSnackBar('コメントのピンどめに失敗しました', 'left', 'bottom', 'error');
      }
    },
    [myInfo.hospitalHashId, refetch]
  );

  const handleUpdateComment = useCallback(
    async (
      faultReceptionHashId: string | undefined,
      logHashId: string | undefined,
      description: string | undefined
    ) => {
      if (!logHashId || !faultReceptionHashId) return;
      try {
        await updateFaultReceptionLog(myInfo.hospitalHashId, faultReceptionHashId, logHashId, {
          description: description || '',
        });
        await refetch();
        openSnackBar('コメントを更新しました');
      } catch (_error) {
        openSnackBar('コメントの更新に失敗しました');
      }
    },
    [myInfo.hospitalHashId, refetch]
  );

  const handleDeleteComment = useCallback(
    async (faultReceptionHashId: string | undefined, logHashId: string | undefined) => {
      if (!logHashId || !faultReceptionHashId) return;
      try {
        await deleteFaultReceptionLog(myInfo.hospitalHashId, faultReceptionHashId, logHashId);
        await refetch();
        openSnackBar('コメントを削除しました');
      } catch (_error) {
        openSnackBar('コメントの削除に失敗しました');
      }
    },
    [myInfo.hospitalHashId, refetch]
  );

  return (
    <ContainerBox>
      <ScrollBox>
        {monthlyProductHistory.map((outer, index) => (
          <React.Fragment key={`${outer.section}_${index}`}>
            <OuterSectionLabel>{outer.section}</OuterSectionLabel>
            {outer.productHistories.map((innerItem) => (
              <HistoryCard
                key={innerItem.logHashId}
                innerItem={innerItem}
                isReadOnly={isReadOnly}
                isAdmin={isAdmin}
                onUpdatePinned={() => {
                  handleUpdatePin(
                    innerItem.faultReceptionHashId,
                    innerItem.logHashId,
                    innerItem.pinned,
                    innerItem.description
                  );
                }}
                onUpdateComment={(comment) => {
                  handleUpdateComment(innerItem.faultReceptionHashId, innerItem.logHashId, comment);
                }}
                onDeleteComment={() => {
                  handleDeleteComment(innerItem.faultReceptionHashId, innerItem.logHashId);
                }}
              />
            ))}
          </React.Fragment>
        ))}
      </ScrollBox>
    </ContainerBox>
  );
});

const OuterSectionLabel = styled(Typography)({
  fontSize: '12px',
  color: '#65676B',
  display: 'contents',
});
