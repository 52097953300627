import {WholeProductEditDialogProps, WholeProductEditDialog} from '@organisms/WholeProductEditDialog';
import {WholeProductEditDialogResult} from '@organisms/WholeProductEditDialog/type';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {useNavigate} from 'react-router-dom';
import {useCallback} from 'react';
import {CreateWholeProduct, WholeProductIndex} from '@modules/products/types';
import {CategoryFormatter} from '@modules/categories/helpers';
import {createWholeProduct} from '@modules/products/api';
import {isNullish} from '@front-libs/helpers';

const convertFormDataToRequestData = (
  formResult: WholeProductEditDialogResult,
  defaultMakerName: string,
  thumbnailUrl: string
): CreateWholeProduct => {
  const isNew = !isNullish(formResult.newMakerName) && formResult.newMakerName !== defaultMakerName;
  return {
    rootCategoryHashId: formResult.rootCategoryHashId,
    narrowCategoryHashId: formResult.narrowCategoryHashId,
    makerHashID: !isNew ? formResult.makerHashId : null, // isNew が true の場合は makerHashId は null
    makerName: isNew ? formResult.newMakerName : null, // isNew が false の場合は newMakerName は null
    name: formResult.name,
    displayName: formResult.displayName,
    approvalNumber: formResult.approvalNumber,
    jmdnCode: formResult.jmdnCode ?? undefined,
    janCode: formResult.janCode ?? undefined,
    isSpecificMaintain: formResult.isSpecificMaintain,
    className: formResult.className,
    catalogPrice: formResult.catalogPrice,
    thumbnailUrl,
  };
};

export const useCopyWholeProduct = (props: {hospitalHashId: string}) => {
  const {hospitalHashId} = props;
  const navigate = useNavigate();

  return useCallback(
    async (data: WholeProductIndex) => {
      const rootCategory = CategoryFormatter.getRootCategory(data.categories);
      const narrowCategory = CategoryFormatter.getNarrowCategory(data.categories);
      try {
        const formData = await dialogHandler.open<WholeProductEditDialogProps, WholeProductEditDialogResult>(
          WholeProductEditDialog,
          {
            title: '登録する機種情報の入力（複製登録）',
            content:
              '選択した院内機種情報を複製し、新しい機種を登録します。\n確認・修正し、よろしければ「機種を確定して次へ」をクリックしてください。',
            alertContent: false,
            recommendResult: {
              rootCategoryHashId: rootCategory?.hashId ?? '',
              narrowCategoryHashId: narrowCategory?.hashId ?? '',
              displayName: data.displayName,
              name: data.name,
              makerHashId: data.maker.hashId,
              newMakerName: data.maker.name,
              approvalNumber: data.approvalNumber ?? '',
              jmdnCode: data.jmdnCode ?? null,
              janCode: data.janCode ?? '',
              isSpecificMaintain: data.isSpecificMaintain ?? null,
              className: data.className,
              catalogPrice: data.catalogPrice ?? null,
            },
          }
        );

        const newProduct = convertFormDataToRequestData(formData, data.maker.name, data.thumbnailUrl);
        const res = await createWholeProduct(hospitalHashId, newProduct);

        navigate(`/products/registration/Step2/${res.data.hashId}`);
      } catch (_e) {
        return null;
      }
    },
    [navigate]
  );
};
