import React, {useCallback} from 'react';
import clsx from 'clsx';
import {
  createStyles,
  Grid,
  makeStyles,
  Card,
  IconButton,
  Theme,
  TextField,
  Menu,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Switch,
  Typography,
  MenuItem,
  Divider,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import {MoreVert, Delete, Check, DragHandle, FileCopyOutlined} from '@material-ui/icons';
import {Field} from '@Apps/InspectionSettings/InspectionEditor/types';
import {FormikErrors} from 'formik';
import {DraggableProvidedDragHandleProps} from 'react-beautiful-dnd';
import {DEFAULT_FIELD_NAME} from '../states';
import {FormError} from '../FormError';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';
import RadioButtonCheckedOutlinedIcon from '@material-ui/icons/RadioButtonCheckedOutlined';
import ExposureZeroOutlinedIcon from '@material-ui/icons/ExposureZeroOutlined';
import ShortTextOutlinedIcon from '@material-ui/icons/ShortTextOutlined';
import SubjectOutlinedIcon from '@material-ui/icons/SubjectOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';

const FieldTypeOptions = [
  {
    label: 'チェックボックス',
    value: 'checkbox',
    icon: <CheckBoxOutlinedIcon />,
  },
  {
    label: 'プルダウン',
    value: 'pull-down',
    icon: <ArrowDropDownCircleOutlinedIcon />,
  },
  {
    label: 'ラジオボタン',
    value: 'radio',
    icon: <RadioButtonCheckedOutlinedIcon />,
  },
  {
    label: '数値',
    value: 'numeric',
    icon: <ExposureZeroOutlinedIcon />,
  },
  {
    label: '単数行入力',
    value: 'text',
    icon: <ShortTextOutlinedIcon />,
  },
  {
    label: '複数行入力',
    value: 'multiline-text',
    icon: <SubjectOutlinedIcon />,
  },
  {
    label: '日付',
    value: 'date',
    icon: <EventOutlinedIcon />,
  },
  {
    label: '時刻',
    value: 'time',
    icon: <QueryBuilderOutlinedIcon />,
  },
] as const;

const useBaseFieldViewStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '12px 0px 0px 0px',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    required: {
      marginLeft: '16px',
      fontSize: '12px',
      color: '#C7243A',
      fontWeight: 'bold',
    },
    inspectionPoint: {
      marginTop: '16px',
      color: theme.palette.grey[600],
    },
    description: {
      color: theme.palette.grey[600],
    },
    content: {
      marginTop: '36px',
      marginBottom: '8px',
    },
    footer: {
      display: 'grid',
      gridTemplateColumns: '1fr max-content max-content',
      alignItems: 'center',
    },
  })
);

type BaseFieldViewProps = {
  field: Field;
  errors: FormikErrors<Field>;
  touched: boolean;
  onClickDuplicate: React.MouseEventHandler;
  onClickDelete: React.MouseEventHandler;
};

const BaseFieldView: React.FC<BaseFieldViewProps> = (props) => {
  const {field, errors, touched, onClickDuplicate, onClickDelete, children} = props;
  const classes = useBaseFieldViewStyle();
  const {
    name,
    inspectionPoint,
    description,
    settings: {showsInspectionPoint, showsDescription},
  } = field;

  return (
    <Grid container className={classes.root} direction="column">
      <div className={classes.titleContainer}>
        <Typography variant="inherit">{name}</Typography>
        {field.required && (
          <Typography className={classes.required} variant="inherit">
            *必須
          </Typography>
        )}
      </div>
      {showsInspectionPoint === true && (
        <Typography className={classes.inspectionPoint} variant="inherit">
          {`点検箇所: ${inspectionPoint ?? ''}`}
        </Typography>
      )}
      {showsDescription === true && (
        <Typography className={classes.description} variant="inherit">{`説明: ${description ?? ''}`}</Typography>
      )}
      <Grid className={classes.content}>{children}</Grid>
      <div className={classes.footer}>
        <Grid item container direction="column">
          {touched && <FormError error={errors.name} />}
          {touched && <FormError error={errors.type} />}
        </Grid>
        <IconButton onClick={onClickDuplicate}>
          <FileCopyOutlined />
        </IconButton>
        <IconButton onClick={onClickDelete}>
          <Delete />
        </IconButton>
      </div>
    </Grid>
  );
};

const useBaseFieldEditStyle = makeStyles(() =>
  createStyles({
    error: {
      marginTop: '4px',
      marginLeft: '0px',
      marginRight: '0px',
    },
    header: {
      display: 'flex',
      alignItems: 'flex-start',
      height: '70px',
    },
    inputName: {
      flexGrow: 1,
    },
    selectTypeContainer: {
      marginLeft: '16px',
      minWidth: '220px',
    },
    selectType: {
      '& .MuiSelect-root': {
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        paddingTop: '8px',
        paddingBottom: '8px',
        '& .MuiListItemIcon-root': {
          alignItems: 'center',
          height: 'max-content',
          minWidth: '35px',
        },
        '& .MuiListItemText-root': {
          margin: 0,
        },
      },
    },
    selectTypeMenu: {
      '& .MuiListItemIcon-root': {
        minWidth: '35px',
      },
    },
    inspectionPointInput: {
      marginTop: '16px',
      width: '100%',
    },
    descriptionInput: {
      marginTop: '16px',
      width: '100%',
    },
    content: {
      marginTop: '32px',
    },
    divider: {
      margin: '16px 0px',
      width: '100%',
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    footerDivider: {
      margin: '0px 32px',
    },
    footerActionAnchor: {
      marginLeft: '8px',
    },
    footerActionMenu: {
      padding: '8px 0px',
    },
    footerActionMenuTitle: {
      padding: '0px 16px',
    },
    footerActionMenuItem: {
      display: 'flex',
      alignItems: 'center',
    },
    footerActionMenuSelected: {
      backgroundColor: '#2A96E8',
      opacity: 0.2,
    },
    footerActionMenuItemIcon: {
      visibility: 'hidden',
    },
    footerActionMenuItemIconSelected: {
      visibility: 'visible',
    },
    footerActionMenuItemText: {
      marginLeft: '8px',
    },
  })
);

type BaseFieldEditProps = {
  field: Field;
  errors: FormikErrors<Field>;
  touched: boolean;
  settings: {label: string; value: string; checked: boolean}[];
  onChangeType: (type: Field['type']) => void;
  onChangeField: (name: string | '', value: unknown, validate: boolean) => void;
  onClickDuplicate: React.MouseEventHandler;
  onClickDelete: React.MouseEventHandler;
};

const BaseFieldEdit: React.FC<BaseFieldEditProps> = (props) => {
  const {field, errors, touched, settings, onChangeType, onChangeField, onClickDuplicate, onClickDelete, children} =
    props;
  const classes = useBaseFieldEditStyle();
  const [actionAnchor, setActionAnchor] = React.useState<Element | null>(null);

  const handleFocusName = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (DEFAULT_FIELD_NAME === e.target.value) {
        onChangeField('name', '', true);
      }
    },
    [onChangeField]
  );

  const handleChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeField('name', e.target.value, true);
    },
    [onChangeField]
  );

  const handleChangeType = useCallback(
    (e: React.ChangeEvent<{value: unknown}>) => {
      const type = (e.target.value ?? null) as Field['type'];
      onChangeType(type);
    },
    [onChangeType]
  );

  const handleChangeRequired = useCallback(
    (_, checked: boolean) => {
      onChangeField('required', checked, false);
    },
    [onChangeField]
  );

  const handleChangeInspectionPoint = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeField('inspectionPoint', e.target.value, false);
    },
    [onChangeField]
  );

  const handleChangeDescription = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeField('description', e.target.value, false);
    },
    [onChangeField]
  );

  const handleOpenMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setActionAnchor(e.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setActionAnchor(null);
  }, []);

  const handleClickSetting = useCallback(
    (_e: React.MouseEvent, value: string, checked: boolean) => {
      const fieldName = `settings.${value}`;
      onChangeField(fieldName, checked, true);
      handleCloseMenu();
    },
    [handleCloseMenu, onChangeField]
  );

  const {
    type,
    name,
    required,
    inspectionPoint = '',
    description = '',
    settings: {showsInspectionPoint, showsDescription},
  } = field;

  return (
    <Grid container direction="column">
      <Grid className={classes.header} alignItems="center">
        <Grid container direction="column">
          <TextField
            className={classes.inputName}
            label="点検内容"
            placeholder="点検内容を入力"
            variant="outlined"
            size="small"
            value={name}
            error={errors?.name !== undefined}
            onFocus={handleFocusName}
            onChange={handleChangeName}
          />
          {touched && <FormError error={errors.name} reserveSpace={true} />}
        </Grid>
        <FormControl className={classes.selectTypeContainer} variant="outlined" size="small">
          <InputLabel htmlFor="field-type">タイプ</InputLabel>
          <Select
            className={classes.selectType}
            label="タイプ"
            value={type}
            error={touched && errors?.type !== undefined}
            onChange={handleChangeType}
            MenuProps={{
              className: classes.selectTypeMenu,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            inputProps={{
              name: 'type',
              id: 'field-type',
              shrink: true,
            }}>
            {FieldTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <ListItemIcon>{option.icon}</ListItemIcon>
                <ListItemText>{option.label}</ListItemText>
              </MenuItem>
            ))}
          </Select>
          {touched && <FormError error={errors.type} reserveSpace={true} />}
        </FormControl>
      </Grid>
      {showsInspectionPoint === true && (
        <TextField
          className={classes.inspectionPointInput}
          variant="standard"
          placeholder="点検箇所"
          value={inspectionPoint}
          onChange={handleChangeInspectionPoint}
        />
      )}
      {showsDescription === true && (
        <TextField
          className={classes.descriptionInput}
          multiline
          rows={3}
          variant="standard"
          placeholder="説明"
          value={description}
          onChange={handleChangeDescription}
        />
      )}
      <Grid className={classes.content}>{children}</Grid>
      <Divider variant="middle" className={classes.divider} />
      <Grid className={classes.footer} justifyContent="flex-end">
        <IconButton onClick={onClickDuplicate}>
          <FileCopyOutlined />
        </IconButton>
        <IconButton onClick={onClickDelete}>
          <Delete />
        </IconButton>
        <Divider className={classes.footerDivider} orientation="vertical" flexItem />
        <FormControlLabel
          label="必須"
          labelPlacement="start"
          checked={required}
          onChange={handleChangeRequired}
          control={<Switch color="primary" />}
        />
        <IconButton className={classes.footerActionAnchor} onClick={handleOpenMenu}>
          <MoreVert />
        </IconButton>
        <Menu
          className={classes.footerActionMenu}
          anchorEl={actionAnchor}
          getContentAnchorEl={null}
          anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
          keepMounted
          open={Boolean(actionAnchor)}
          onClose={handleCloseMenu}>
          <Typography className={classes.footerActionMenuTitle} variant="inherit">
            表示
          </Typography>
          {settings.map((sec, index) => {
            return (
              <MenuItem
                key={index}
                className={classes.footerActionMenuItem}
                onClick={(e) => handleClickSetting(e, sec.value, !sec.checked)}>
                <Check
                  className={clsx(
                    classes.footerActionMenuItemIcon,
                    sec.checked ? classes.footerActionMenuItemIconSelected : null
                  )}
                />
                <Typography className={classes.footerActionMenuItemText} variant="inherit">
                  {sec.label}
                </Typography>
              </MenuItem>
            );
          })}
        </Menu>
      </Grid>
    </Grid>
  );
};

const useBaseFieldStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: '4px 8px 8px 30px',
    },
    dragHandle: {
      width: '100%',
      height: '16px',
      marginBottom: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > svg': {
        visibility: 'hidden',
      },
      '&:hover > svg': {
        visibility: 'visible',
      },
    },
    focused: {
      padding: '16px 24px 16px 24px',
      borderLeftWidth: '6px',
      borderLeftStyle: 'solid',
      borderLeftColor: theme.palette.primary.main,
    },
    dragHandleIcon: {
      color: theme.palette.grey[300],
    },
  })
);

type BaseFieldProps = {
  dragHandlerProps: DraggableProvidedDragHandleProps;
  field: Field;
  errors: FormikErrors<Field>;
  touched: boolean;
  focused: boolean;
  settings: {label: string; value: string; checked: boolean}[];
  onChangeType: (type: Field['type']) => void;
  onChangeField: (name: string | '', value: unknown, validate: boolean) => void;
  onClickDuplicate: React.MouseEventHandler;
  onClickDelete: React.MouseEventHandler;
};

export const BaseField: React.FC<BaseFieldProps> = (props) => {
  const {
    dragHandlerProps,
    field,
    errors,
    touched,
    focused,
    settings,
    onChangeType,
    onChangeField,
    onClickDuplicate,
    onClickDelete,
    children,
  } = props;
  const classes = useBaseFieldStyle();

  return (
    <Card elevation={2} className={clsx(classes.root, focused ? classes.focused : null)}>
      <Grid {...dragHandlerProps} item className={classes.dragHandle} justifyContent="center">
        <DragHandle className={classes.dragHandleIcon} fontSize="large" />
      </Grid>
      {focused === true ? (
        <BaseFieldEdit
          field={field}
          errors={errors}
          touched={touched}
          settings={settings}
          onChangeType={onChangeType}
          onChangeField={onChangeField}
          onClickDuplicate={onClickDuplicate}
          onClickDelete={onClickDelete}>
          {children}
        </BaseFieldEdit>
      ) : (
        <BaseFieldView
          field={field}
          errors={errors}
          touched={touched}
          onClickDuplicate={onClickDuplicate}
          onClickDelete={onClickDelete}>
          {children}
        </BaseFieldView>
      )}
    </Card>
  );
};
