import {IconButton, styled} from '@material-ui/core';
import React, {useCallback, useRef} from 'react';
import {openSnackBar} from '@components/molecules/SnackBar';
import {uploadFile} from '@modules/files/api';
import {FileIndex} from 'src/modules/files/types';
import {createHospitalProductFiles} from '@modules/hospital_products/api';
import {useSetAtom} from 'jotai';
import {refetchAtom} from '@components/organisms/HospitalProductFileList';
import {useParams} from 'react-router-dom';

const UploadIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.41 13.908L11 11.328L11 20.998L13 20.998L13 11.328L15.59 13.908L17 12.498L12 7.49805L7 12.498L8.41 13.908Z"
        fill="#172B4D"
      />
      <rect x="3" y="3" width="18" height="2" rx="1" fill="#172B4D" />
    </svg>
  );
};

const StyledInput = styled('input')({
  display: 'none',
});

type Props = {
  hospitalHashId: string;
};

/**
 * スマホ用の機器詳細ヘッダーに表示されるアップロードボタン
 * @param param0
 * @returns
 */
export const HeaderFileUploadButton = ({hospitalHashId}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {hashId} = useParams();
  const setIsRefetch = useSetAtom(refetchAtom);

  const handleUploadFile = useCallback(
    async (newFile: FileIndex, isFileSharingAcrossProducts = false) => {
      await createHospitalProductFiles(hospitalHashId, hashId ?? '', {
        fileHashIds: [newFile.hashId],
        isFileSharingAcrossProducts: isFileSharingAcrossProducts,
      });
    },
    [hospitalHashId, hashId]
  );

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const fileUploaded = event.target.files[0];

    try {
      const newFile = await uploadFile({
        file: fileUploaded,
        fileName: fileUploaded.name,
        category: 'inspection_result',
      });

      await handleUploadFile(newFile.data);
      setIsRefetch(true);
    } catch (_e) {
      openSnackBar('ファイルのアップロードに失敗しました', 'left', 'bottom', 'error');
    }
  };

  /** input要素をクリックして動作させる */
  const onClickUpLoad = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <IconButton size="small" onClick={onClickUpLoad}>
        <UploadIcon />
      </IconButton>
      <StyledInput type="file" onChange={handleChange} ref={fileInputRef} />
    </>
  );
};
