import {atom} from 'jotai';
import {_inspectionResultStatusAtom, _inspectionTypeAtom, _scheduledTimeRangeAtom} from './states';
import dayjs from 'dayjs';
import {RFC3339Format} from '@front-libs/helpers';
import {ViewInspectionResultStatus, ViewInspectionType} from '../../types';

export const getValidScheduledAtFrom = (
  inspectionType: ViewInspectionType,
  inspectionStatus: ViewInspectionResultStatus,
  scheduledAtFrom: Date | null
) => {
  if (inspectionType !== 'periodic') {
    return scheduledAtFrom !== null ? dayjs(scheduledAtFrom) : undefined;
  }

  // unplannedの場合は予定日の下限がデフォルトで設定されている
  if (scheduledAtFrom !== null) {
    if (inspectionStatus === 'unplanned') {
      return dayjs.max([dayjs().startOf('month'), dayjs(scheduledAtFrom).startOf('day')]);
    } else {
      return dayjs(scheduledAtFrom).startOf('day');
    }
  } else if (inspectionStatus === 'unplanned') {
    return dayjs().startOf('month');
  }
};

export const getValidScheduledAtTo = (
  inspectionType: ViewInspectionType,
  inspectionStatus: ViewInspectionResultStatus,
  scheduledAtTo: Date | null
) => {
  if (inspectionType !== 'periodic') {
    return scheduledAtTo !== null ? dayjs(scheduledAtTo) : undefined;
  }

  // overdueの場合は予定日の上限がデフォルトで設定されている
  if (scheduledAtTo !== null) {
    if (inspectionStatus === 'overdue') {
      return dayjs.min([dayjs().subtract(1, 'month').endOf('month'), dayjs(scheduledAtTo).endOf('day')]);
    } else {
      return dayjs(scheduledAtTo).endOf('day');
    }
  } else if (inspectionStatus === 'overdue') {
    return dayjs().subtract(1, 'month').endOf('month');
  }
};

export const queryScheduledAtFromAtom = atom((get) => {
  const inspectionType = get(_inspectionTypeAtom);
  const inspectionResultStatus = get(_inspectionResultStatusAtom);
  const [scheduledAtFrom] = get(_scheduledTimeRangeAtom);

  const validFrom = getValidScheduledAtFrom(inspectionType, inspectionResultStatus, scheduledAtFrom);

  return validFrom?.format(RFC3339Format);
});

export const queryScheduledAtToAtom = atom((get) => {
  const inspectionType = get(_inspectionTypeAtom);
  const inspectionResultStatus = get(_inspectionResultStatusAtom);
  const [, scheduledTimeAtTo] = get(_scheduledTimeRangeAtom);

  const validTo = getValidScheduledAtTo(inspectionType, inspectionResultStatus, scheduledTimeAtTo);

  return validTo?.format(RFC3339Format);
});
