import {Dialog, Grid} from '@material-ui/core';
import React, {useCallback, useMemo, useState} from 'react';
import {MakerInspectionResultIndex} from '@modules/maker_inspection_results/types';
import {MakerInspectionHeader} from './MakerInspectionHeader';
import {SideNav} from './SideNav';
import {createMakerInspectionResultFiles, updateMakerInspectionResult} from '@modules/maker_inspection_results/api';
import {FileList} from '@organisms/Files/FileList';
import {useFetchMakerInspectionResultFilesQuery} from '@modules/maker_inspection_results/hooks';
import {FileIndex} from '@modules/files/types';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {
  FinishInspectionDialog,
  FinishInspectionDialogProps,
  FinishInspectionDialogResult,
} from './FinishInspectionDialog';
import {convertDateToSimpleDateTime, isNullish} from '@front-libs/helpers';
import {useLocation, useNavigate} from 'react-router-dom';
import {openSnackBar} from '@molecules/SnackBar';
import {useFetchMakerInspectionSettingsQuery} from '@modules/maker_inspection_settings/hooks';
import {createMakerInspectionSetting, updateMakerInspectionSetting} from '@modules/maker_inspection_settings/api';
import dayjs from 'dayjs';
import {isNaN} from 'lodash';
import {useTableLayout} from '@modules/table_layout/hooks/useTableLayout';

type Props = {
  hospitalHashId: string;
  makerInspectionResult: MakerInspectionResultIndex;
};

type InspectionState = {
  inspectorHashId: string;
};

export const NewMakerInspectionDialog: React.FC<Props> = ({hospitalHashId, makerInspectionResult}) => {
  const {state} = useLocation();
  const [inspectionFee, setInspectionFee] = useState<number | null>();
  const [comment, setComment] = useState<string>('');
  const [tableLayout] = useTableLayout('fileList');
  const [order, setOrder] = useState<string>();

  const navigate = useNavigate();

  // 点検予定からの実施の場合は 前画面で入力した 点検者 を取得
  // Note: 新規保守点検の場合 makerInspectionResult.inspector に 点検者(ログイン者) のデータが入るが、
  // 自動的に作成される次回点検では makerInspectionResult.inspector は null になっている。
  // → 点検者は直前の画面で指定している。
  const inspectorHashId = useMemo(() => (state as InspectionState)?.inspectorHashId, [state]);

  const {
    data: files,
    isLoading,
    refetch,
  } = useFetchMakerInspectionResultFilesQuery(hospitalHashId, makerInspectionResult.hashId, order);

  const {data: settings} = useFetchMakerInspectionSettingsQuery(
    hospitalHashId,
    makerInspectionResult.hospitalProduct.hashId
  );

  const makerInspectionSetting = useMemo(() => settings?.data.at(0), [settings?.data]);
  const calculatedNextInspectionDate = useMemo(() => {
    if (
      isNullish(makerInspectionSetting) ||
      isNullish(makerInspectionSetting?.makerInspectionWholeProductSetting.inspectionPeriod)
    )
      return undefined;
    const nextDate = dayjs()
      .add(makerInspectionSetting.makerInspectionWholeProductSetting.inspectionPeriod, 'year')
      .toDate();
    if (nextDate > makerInspectionSetting.dueDateOfMakerInspection) return undefined;

    return nextDate;
  }, [makerInspectionSetting]);

  const handleRefetch = () => refetch();

  const handleUploadFile = useCallback(
    async (file: FileIndex) => {
      try {
        await createMakerInspectionResultFiles(hospitalHashId, makerInspectionResult.hashId, {
          fileHashIds: [file.hashId],
        });
        refetch();
      } catch (_err) {
        // empty function
      }
    },
    [hospitalHashId, makerInspectionResult.hashId, refetch]
  );

  const handleComplete = useCallback(async () => {
    const res = await dialogHandler.open<FinishInspectionDialogProps, FinishInspectionDialogResult>(
      FinishInspectionDialog,
      {
        completedAt: convertDateToSimpleDateTime(new Date()),
        inspectorHashId: inspectorHashId ?? makerInspectionResult.inspector?.hashId ?? '', // 点検者は画面での選択を優先
        nextInspectionDate: calculatedNextInspectionDate,
        dueDateOfMakerInspection: makerInspectionSetting?.dueDateOfMakerInspection,
      }
    );

    await updateMakerInspectionResult(hospitalHashId, {
      makerInspectionResultHashId: makerInspectionResult.hashId,
      status: 'completed',
      inspectionFee: inspectionFee !== 0 ? inspectionFee ?? undefined : undefined,
      comment: comment,
      nextScheduledTime: res?.nextInspectionDate,
      inspectorUserHashId: res?.inspector,
      completedAt: res?.completedAt,
    });

    if (isNullish(makerInspectionSetting)) {
      const inspectionPeriodDiff = dayjs(res?.completedAt).diff(res?.nextInspectionDate, 'month') / 12;
      const inspectionPeriod = Math.ceil(inspectionPeriodDiff < 0 ? -inspectionPeriodDiff : inspectionPeriodDiff);
      await createMakerInspectionSetting(hospitalHashId, {
        hospitalProductHashId: makerInspectionResult.hospitalProduct.hashId,
        dueDateOfMakerInspection: res?.dueDateOfMakerInspection,
        inspectionPeriod: isNaN(inspectionPeriod) || inspectionPeriod === 0 ? 1 : inspectionPeriod,
        inspectionPeriodUnit: 'year',
        nextInspectionDate: res?.nextInspectionDate,
      });
    } else {
      const inspectionPeriod = isNullish(makerInspectionSetting.makerInspectionWholeProductSetting.inspectionPeriod)
        ? dayjs(res?.completedAt).diff(res?.nextInspectionDate, 'month') / 12
        : undefined;
      await updateMakerInspectionSetting(hospitalHashId, makerInspectionSetting.hashId, {
        dueDateOfMakerInspection: isNullish(makerInspectionSetting.dueDateOfMakerInspection)
          ? res?.dueDateOfMakerInspection
          : makerInspectionSetting.dueDateOfMakerInspection,
        inspectionPeriod: !isNullish(inspectionPeriod)
          ? Math.ceil(inspectionPeriod < 0 ? -inspectionPeriod : inspectionPeriod)
          : undefined,
        inspectionPeriodUnit: 'year',
        nextInspectionDate: res?.nextInspectionDate,
      });
    }

    openSnackBar('点検の記録を完了しました。');
    navigate(`/inspection_v2/results`);
    // 依存が多いので一旦exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calculatedNextInspectionDate,
    comment,
    hospitalHashId,
    inspectionFee,
    makerInspectionResult.hashId,
    makerInspectionResult.hospitalProduct.hashId,
    makerInspectionResult.inspector?.hashId,
    makerInspectionSetting,
    navigate,
  ]);

  // 点検の入力を中止する。現状入力されている情報を更新して点検一覧画面に戻る。
  // この時、ステータスは変更しない
  const handleAbort = useCallback(async () => {
    await updateMakerInspectionResult(hospitalHashId, {
      makerInspectionResultHashId: makerInspectionResult.hashId,
      inspectionFee: inspectionFee !== 0 ? inspectionFee ?? undefined : undefined,
      comment: comment,
    });
    openSnackBar('点検の記録を中断しました。');
    navigate(`/inspection_v2/results`);
  }, [comment, hospitalHashId, inspectionFee, makerInspectionResult.hashId, navigate]);

  const handleOrderChange = useCallback(
    (columnIndex: number, orderDirection: 'asc' | 'desc') => {
      setOrder(`${orderDirection === 'desc' ? '-' : ''}${String(tableLayout.currentLayout[columnIndex].field)}`);
    },
    [tableLayout.currentLayout]
  );

  return (
    <Dialog fullScreen open={true}>
      <MakerInspectionHeader
        makerInspectionResult={makerInspectionResult}
        onClickComplete={handleComplete}
        onAbort={handleAbort}
      />
      <Grid container>
        <Grid item style={{width: 352, height: 'calc(100vh - 100px)'}}>
          <SideNav onChangeInspectionFee={setInspectionFee} onChangeComment={setComment} />
        </Grid>
        <Grid item style={{height: 'calc(100vh - 100px)', width: 'calc(100% - 352px)', background: '#f0f2f5'}}>
          <div style={{padding: 24}}>
            <FileList
              files={files}
              fileCategory={'maker_inspection_result'}
              isLoading={isLoading}
              onDeleteFile={handleRefetch}
              onEditFileName={handleRefetch}
              onUploadFile={handleUploadFile}
              onOrderChange={handleOrderChange}
            />
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};
