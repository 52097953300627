import React, {useCallback, useReducer, useState} from 'react';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {recommendWholeProduct} from '@modules/whole_products/api';
import {openSnackBar} from '@molecules/SnackBar';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {WholeProductEditDialogProps, WholeProductEditDialog} from '@organisms/WholeProductEditDialog/index';
import {WholeProductSearchFormData} from '../common/validation';
import {CreateWholeProduct} from '@modules/products/types';
import {createWholeProduct} from '@modules/products/api';
import {useNavigate} from 'react-router-dom';
import {reducer} from './reducer';
import {
  ERROR_ACTION,
  NOT_FOUND_ACTION,
  SEARCH_COMPLETE_ACTION,
  SEARCH_START_ACTION,
  recommendInitialState,
  searchRequestInitialState,
} from '../common/const';
import {FetchCompaniesParam, useFetchCompaniesQuery} from '@modules/companies/api';
import {QueryParams, WholeProductUnregisteredSearchState} from '../common/type';
import {RecommendWholeProductRequest} from '@modules/whole_products/type';
import {WholeProductEditDialogResult} from '@components/organisms/WholeProductEditDialog/type';

export const useWholeProductUnregisteredSearch = (queryParams: QueryParams) => {
  const [searchKeys, setSearchKeys] = useState<WholeProductUnregisteredSearchState>(searchRequestInitialState);
  const [state, dispatch] = useReducer(reducer, recommendInitialState);
  const {myInfo} = useMyInfo();
  const navigate = useNavigate();
  const [fetchCompaniesQueryParam, setFetchCompaniesQueryParam] = useState<FetchCompaniesParam>({
    is_perfect_match_search: true,
  });
  const {data: companies} = useFetchCompaniesQuery(myInfo.hospitalHashId, fetchCompaniesQueryParam);

  const handleSearch = async (formData: WholeProductSearchFormData) => {
    try {
      dispatch({type: SEARCH_START_ACTION});
      const recommendProduct: RecommendWholeProductRequest = {
        name: formData.name,
        displayName: formData.displayName,
        makerName: formData.makerName,
        janCode: formData.janCode || null,
        approvalNumber: formData.approvalNumber || null,
      };
      const {data} = await recommendWholeProduct(myInfo.hospitalHashId || '', recommendProduct);

      // 検索結果がない場合は、not_foundモードに変更
      if (data.recommendDisplayName === '') {
        dispatch({type: NOT_FOUND_ACTION});
        return;
      }
      dispatch({type: SEARCH_COMPLETE_ACTION, payload: data});
      setFetchCompaniesQueryParam({name: data.recommendMakerName, is_perfect_match_search: true});
    } catch (e) {
      console.error(e);
      dispatch({type: ERROR_ACTION});
    }
  };

  const nameValue = queryParams.name as string;

  const registerProductFromRecommend = async () => {
    const isCompanyExist = state.searchResult && companies && companies.data.length > 0;
    let newProduct: CreateWholeProduct;
    try {
      const searchResult = state.searchResult;
      const formData = await dialogHandler.open<WholeProductEditDialogProps>(WholeProductEditDialog, {
        recommendResult: {
          rootCategoryHashId: searchResult ? searchResult.recommendRootCategory.hashId : '',
          narrowCategoryHashId: searchResult ? searchResult.recommendNarrowCategory.hashId : '',
          displayName: searchResult ? searchResult.recommendDisplayName : '',
          name: searchResult && nameValue ? nameValue : '',
          makerHashId: isCompanyExist ? companies.data[0].hashId : null,
          newMakerName: searchResult ? searchResult.recommendMakerName : null,
          approvalNumber: searchResult ? searchResult.recommendApprovalNumber : null,
          jmdnCode: searchResult ? parseInt(searchResult.recommendJmdnCode, 10) : null,
          janCode: searchResult ? searchResult.recommendJanCode : null,
          isSpecificMaintain: null,
          className: null,
          catalogPrice: null,
        },
        title: '選択した機種情報の確認・修正',
        content: '確認・修正し、よろしければ「機種を確定して次へ」をクリックしてください。',
      });

      const isNew = Boolean(formData.newMakerName);

      newProduct = {
        rootCategoryHashId: formData.rootCategoryHashId,
        narrowCategoryHashId: formData.narrowCategoryHashId,
        makerHashID: isNew ? undefined : formData.makerHashId,
        makerName: isNew ? formData.newMakerName : undefined,
        name: formData.name,
        displayName: formData.displayName,
        approvalNumber: formData.approvalNumber,
        jmdnCode: formData.jmdnCode ? formData.jmdnCode : undefined,
        janCode: formData.janCode,
        isSpecificMaintain: formData.isSpecificMaintain,
        className: formData.className,
        catalogPrice: formData.catalogPrice,
      };
    } catch (_e) {
      return null;
    }

    try {
      const res = await createWholeProduct(myInfo.hospitalHashId, newProduct);
      return res.data.hashId;
    } catch (e) {
      console.error(e);
      openSnackBar('機種の追加登録に失敗しました。', 'left', 'bottom', 'error');
      return null;
    }
  };

  const handleEditWholeProduct = useCallback(async () => {
    const hashId = await registerProductFromRecommend();
    if (hashId !== null) {
      navigate(`/products/registration/Step2/${hashId}?isBlocked=true`);
    }
  }, [navigate, registerProductFromRecommend]);

  const handleWholeProductEditDialogResult = async () => {
    try {
      return await dialogHandler.open<WholeProductEditDialogProps, WholeProductEditDialogResult>(
        WholeProductEditDialog,
        {
          title: '登録する機種情報の入力',
        }
      );
    } catch (_e) {
      return null;
    }
  };

  const registerProduct = async () => {
    const formData = await handleWholeProductEditDialogResult();
    if (!formData) return;

    const isNew = Boolean(formData.newMakerName);
    const newProduct: CreateWholeProduct = {
      rootCategoryHashId: formData.rootCategoryHashId,
      narrowCategoryHashId: formData.narrowCategoryHashId,
      makerHashID: isNew ? undefined : formData.makerHashId,
      makerName: isNew ? formData.newMakerName : undefined,
      name: formData.name,
      displayName: formData.displayName,
      approvalNumber: formData.approvalNumber,
      jmdnCode: formData.jmdnCode,
      janCode: formData.janCode,
      isSpecificMaintain: formData.isSpecificMaintain,
      className: formData.className,
      catalogPrice: formData.catalogPrice,
    };

    try {
      const res = await createWholeProduct(myInfo.hospitalHashId, newProduct);
      openSnackBar('機種を追加登録しました。');
      return res.data.hashId;
    } catch (e) {
      console.error(e);
      openSnackBar('機種の追加登録に失敗しました。', 'left', 'bottom', 'error');
      return null;
    }
  };

  const handleEditBlankWholeProduct = useCallback(async () => {
    const hashId = await registerProduct();
    if (hashId) {
      navigate(`/products/registration/Step2/${hashId}?isBlocked=true`);
    }
  }, [navigate, registerProductFromRecommend]);

  return {
    searchKeys,
    setSearchKeys,
    handleSearch,
    handleEditWholeProduct,
    state,
    handleEditBlankWholeProduct,
  };
};
