import React, {useCallback, useMemo} from 'react';
import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {InspectionTableIndex} from '@modules/inspections/types';
import {Section} from '@components/molecules/InspectionTableFormItems/Section';
import {Item} from '@Apps/InspectionResult/pc/EditInspectionResult/Item';
import {InspectionItem} from '@modules/inspections/api';
import {MenuItemType, PopperMenuButton} from '@components/molecules/Buttons/PopperMenuButton';
import {useLatestInspectionResult} from '../common/hooks';
import {useFormikContext} from 'formik';
import {FormValue} from '../common/types';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {MessageDialog} from '@molecules/Dialogs/MessageDialog';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {isNullish} from '@front-libs/helpers';
import {openSnackBar} from '@molecules/SnackBar';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      margin: '24px 0px',
    },
    items: {
      maxWidth: '700px',
      '& > div:not(:first-child)': {
        marginTop: '42px',
      },
    },
    actionBtn: {
      position: 'absolute',
      top: 184,
      right: 0,
      margin: '0px 24px 12px',
      border: '1px solid #C6CBD4',
      background: '#EAEBEF',
    },
  })
);

type ItemsFormProps = {
  inspectionTable: InspectionTableIndex;
  inspectionHashId: string;
  hospitalProductHashId: string;
};

export const ItemsForm: React.FC<ItemsFormProps> = (props) => {
  const {inspectionTable, inspectionHashId, hospitalProductHashId} = props;
  const classes = useStyles();

  const form = useFormikContext<FormValue>();

  const {data} = useLatestInspectionResult(inspectionHashId, hospitalProductHashId);
  const hasNoPastInspection = useMemo(() => data === null, [data]);
  const isInspectionChanged = useMemo(
    () => data?.inspectionTable.hashId !== inspectionTable.hashId,
    [data, inspectionTable.hashId]
  );
  const isPresetDisabled = useMemo(
    () => hasNoPastInspection || isInspectionChanged,
    [hasNoPastInspection, isInspectionChanged]
  );

  const actionMenuItems: MenuItemType[] = useMemo(
    () => [
      {
        label: '前回の点検結果を反映',
        value: 'preset',
        disableMenuItem: isPresetDisabled,
        tooltipOption: {
          enableTooltip: isPresetDisabled,
          title: isInspectionChanged
            ? '点検表が前回から変更されているため\n前回の点検結果を反映できません'
            : '点検結果が存在しないため\n使用できません。',
          arrow: true,
        },
      },
    ],
    [isPresetDisabled, isInspectionChanged]
  );

  const handleClickPreset = useCallback(() => {
    form.resetForm();

    (data?.items ?? []).forEach((resultItem) => {
      // FIX ME: formのhash keyと最新の点検結果のhash keyのcaseが一致しないため、暫定的にlowercaseにして特定する
      const searchKey = resultItem.id.toLowerCase();
      const formKey = Object.keys(form.values.items).find((k) => k.toLowerCase() === searchKey);

      if (isNullish(formKey)) return;

      if (resultItem.type === 'multi-select') {
        // multi-selectの場合、formのvalueのtype({ [key: string]: boolean; })が最新の点検結果のvalueのtype(string[])と一致しないため、
        // resultItem.values(array)をloopで回してチェックされているものにtrueを付与。
        // Type 'MultiSelectResultItem' is not assignable to type 'MultiSelectField'.
        resultItem.values?.forEach((resultValue) => {
          form.setFieldValue(`items.${formKey}.values.${resultValue}`, true);
        });
      } else if (resultItem.type === 'time') {
        // 時間型の場合、formには時・分までしか入らないが、resultItemのvalueは時・分・秒まで入っているため、秒を削除
        form.setFieldValue(`items.${formKey}.value`, resultItem.value?.slice(0, -3) ?? '');
      } else {
        form.setFieldValue(`items.${formKey}`, resultItem);
      }
    });

    openSnackBar('前回の点検結果を反映しました。');
  }, [data, form]);

  const handleActionClick = useCallback(
    async (item: MenuItemType) => {
      switch (item.value) {
        case 'preset':
          if (form.dirty) {
            await dialogHandler.open(MessageDialog, {
              title: '前回の点検結果を反映',
              content:
                '点検表に値が入力されています。\n点検結果を反映した場合、現在入力している内容は消失し、\n元に戻すことができません。\n\n点検の記録を反映してよろしいですか？',
              positiveButtonLabel: '実行',
            });
          }
          if (data?.result === 'failed') {
            await dialogHandler.open(AlertDialog, {
              title: '異常値を含んだ点検を反映しますか？',
              content: '前回の点検記録には異常値が含まれます。\n点検の記録を反映してよろしいですか？',
              positiveButtonLabel: 'OK',
            });
          }
          handleClickPreset();
          break;
        default:
          break;
      }
    },
    [form.dirty, handleClickPreset, data]
  );

  if (!inspectionTable) {
    // todo: show message
    return null;
  }

  return (
    <>
      <Grid className={classes.root} container justifyContent="center">
        <Grid container direction="column" className={classes.items}>
          {inspectionTable.items.map((item, i) => {
            if (item.type === 'section') {
              return (
                <Section key={i} name={item.name}>
                  {item.items.map((sectionItem, j) => (
                    <Item key={j} item={sectionItem as InspectionItem & {id: string}} />
                  ))}
                </Section>
              );
            } else {
              return <Item key={i} item={item as InspectionItem & {id: string}} />;
            }
          })}
        </Grid>
      </Grid>
      <PopperMenuButton
        buttonProps={{variant: 'contained', disableElevation: true, className: classes.actionBtn}}
        menuItemList={actionMenuItems}
        onMenuClick={handleActionClick}>
        アクション
      </PopperMenuButton>
    </>
  );
};
