import React from 'react';
import {createStyles, Grid, makeStyles, Theme, TextField} from '@material-ui/core';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    timeFieldContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    timeField: {
      width: '100%',
    },
  })
);

type TimeFieldProps = {
  focused: boolean;
};

export const TimeField: React.FC<TimeFieldProps> = (props) => {
  const {focused} = props;
  const classes = useStyles();

  return (
    <Grid container direction="column" md={6}>
      <Grid className={classes.timeFieldContainer}>
        <TextField className={classes.timeField} type="time" disabled={focused} variant="standard" />
      </Grid>
    </Grid>
  );
};
