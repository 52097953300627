import {Box, Typography} from '@mui/material';
import {ProductThumbnail} from './ProductThumnail';
import React from 'react';

export const ProductHeader = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '44px',
        backgroundColor: '#f0f0f0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px solid red',
        position: 'relative',
      }}>
      <Typography>プロダクト Header</Typography>
      <ProductThumbnail />
    </Box>
  );
};
