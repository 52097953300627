import React, {useMemo} from 'react';
import {styles} from './styles';
import {Box, IconButton, Typography} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import {AlertVariant} from './types';

export type PageHeaderAlertProps = {
  variant: AlertVariant;
  onClose: VoidFunction;
  children: React.ReactNode;
};

/**
 *
 * グローバルヘッダー直下に表示する注意喚起用のパネル
 *
 * @param props.onClose 閉じる処理
 * @param props.children アラート内容
 * @param props.variant アラートの種類
 *
 * @returns
 */
export const PageHeaderAlert = (props: PageHeaderAlertProps) => {
  const {onClose, children, variant} = props;
  const variantStyles = useMemo(() => styles(variant), [variant]);

  return (
    <Box sx={variantStyles.root}>
      <Box sx={variantStyles.contentWrapper}>
        <InfoOutlinedIcon sx={variantStyles.iconColor} fontSize="medium" />
        <Box sx={variantStyles.content}>{children}</Box>
      </Box>
      <Box>
        <IconButton aria-label="close" size="small" onClick={onClose}>
          <CloseIcon sx={variantStyles.iconColor} fontSize="medium" />
        </IconButton>
      </Box>
    </Box>
  );
};
