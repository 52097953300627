/* eslint-disable no-irregular-whitespace */
import React, {useCallback} from 'react';
import {Button} from '@material-ui/core';
import {Dropzone} from '@molecules/Dropzone';
import {Table} from '@molecules/Table';
import {Column} from '@molecules/Table/props';
import {openSnackBar} from '@molecules/SnackBar';
import {useSteps} from '../hooks';
import {PageDescription, PageTitle, ProcessTitle} from '../common/PageComponents';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {HospitalRoomTable, useImportHospitalRoomsExcel} from './hooks';

const columns: Column<HospitalRoomTable>[] = [
  {title: '大エリア', field: 'wardName'},
  {
    title: '小エリア',
    field: 'roomName',
    render: ({isGroundFloor, floorNumber, roomName}) => `${isGroundFloor ? '' : '地下'}${floorNumber}階 ${roomName}`,
  },
  {
    title: '貸出先として登録',
    field: 'showRentalPlace',
    render: ({showRentalPlace}) => (showRentalPlace === true ? 'する' : 'しない'),
  },
];

export const HospitalRoomImport: React.FC = () => {
  const {next} = useSteps();
  const {myInfo} = useMyInfo();

  const {isValidFile, uploadFile, hospitalRoomData, submitHospitalRoomData} = useImportHospitalRoomsExcel(
    myInfo.hospitalHashId
  );

  const handleUpload = useCallback(
    async (acceptedFiles: File[]) => {
      uploadFile(acceptedFiles[0]);
    },
    [uploadFile]
  );

  const handleImportRoomData = useCallback(async () => {
    try {
      await submitHospitalRoomData();
      openSnackBar('登録が完了しました。');
      next(true);
    } catch (_error) {
      openSnackBar('登録に失敗しました。', 'left', 'bottom', 'error');
    }
  }, [submitHospitalRoomData, next]);

  return (
    <>
      <PageTitle>建物・場所情報登録</PageTitle>
      <PageDescription>ExcelデータからHITOTSUを利用する建物・場所の情報を登録することができます。</PageDescription>

      <ProcessTitle id="prepare_for_excel_data">1. Excelデータの準備</ProcessTitle>
      <PageDescription>
        HITOTSUの担当者から提供している建物・場所登録用のファイルから建物・場所の一括登録処理を行います。
      </PageDescription>

      <ProcessTitle id="upload_excel">2. Excelデータのアップロード</ProcessTitle>
      <PageDescription>
        建物・場所登録用ファイルにHITOTSUに登録したい場所の情報を記載し、ファイルをアップロードしてください。
      </PageDescription>
      <Dropzone
        onDrop={handleUpload}
        accept={['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
      />

      <ProcessTitle style={{marginTop: 32}} id="register_data">
        3. データの登録
      </ProcessTitle>
      <PageDescription>
        アップロードされたファイルを元に、HITOTSUへのデータ登録内容の確認をいただきます。
        <br />
        問題がなければ「登録する」ボタンをクリックしてください。
      </PageDescription>
      {hospitalRoomData.length > 0 && (
        <>
          <Button
            color="primary"
            variant="contained"
            style={{width: 192, marginBottom: 32}}
            disabled={!isValidFile || hospitalRoomData.length === 0}
            onClick={handleImportRoomData}>
            登録する
          </Button>
          <Table<HospitalRoomTable> showSelection={false} data={hospitalRoomData} columns={columns} />
        </>
      )}
    </>
  );
};
