import React, {useMemo, useCallback} from 'react';
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  TextField,
  FormControlLabel,
  Typography,
  Radio,
  RadioGroup,
  FormHelperText,
  IconButton,
} from '@material-ui/core';
import {Clear} from '@material-ui/icons';
import {RadioField as RadioFieldType} from '@Apps/InspectionSettings/InspectionEditor/types';
import {FormikErrors, getIn} from 'formik';
import Select from 'react-select';
import {theme} from '@atoms/theme';
import {FormError} from '../FormError';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    editRoot: {
      margin: '0px',
    },
    inputOptionContainer: {
      height: '62px',
    },
    radioForm: {
      display: 'flex',
      alignItems: 'center',
    },
    option: {
      display: 'flex',
      alignItems: 'center',
    },
    radioLabel: {
      marginLeft: '24px',
    },
    clearIcon: {
      padding: '0px',
    },
    optionError: {
      marginTop: '0px',
      marginLeft: '54px',
    },
    newOption: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    newOptionIndex: {
      color: 'rgba(0, 0, 0, 0.38)',
    },
    newOptionLabel: {
      color: '#7A869A',
      marginLeft: '24px',
    },
    viewSelector: {
      width: '200px',
    },
    summaryErrorContainer: {
      marginTop: '12px',
    },
  })
);

type RadioFieldProps = {
  field: RadioFieldType;
  errors: FormikErrors<RadioFieldType> & {_options?: string};
  focused: boolean;
  placeholder: string;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  onChangeField: (fieldName: string | '', value: any, validate: boolean) => void;
};

export const RadioField: React.FC<RadioFieldProps> = (props) => {
  const {field, errors, focused, placeholder, onChangeField} = props;
  const classes = useStyles();

  const handleChangeOptionLabel = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
      if (field.options[index]) {
        onChangeField(
          `options[${index}]`,
          {
            label: e.target.value,
            value: e.target.value,
          },
          true
        );
      }
    },
    [field.options, onChangeField]
  );

  const handleDeleteOption = useCallback(
    (_e: React.MouseEvent, index: number) => {
      const newField = {
        ...field,
        options: [...field.options],
      };

      const deletedOption = newField.options[index];
      newField.options.splice(index, 1);

      if (!!newField?.validators?.values && deletedOption.value === field.validators.values[0]) {
        newField.validators.values = [];
      }

      onChangeField('', newField, true);
    },
    [field, onChangeField]
  );

  const handleClickNewOption = useCallback(
    (e: React.MouseEvent) => {
      // to prevent double-creating
      e.preventDefault();
      const newIndex = field.options.length;
      onChangeField(
        `options[${newIndex}]`,
        {
          label: '',
          value: '',
        },
        false
      );
      return false;
    },
    [field.options.length, onChangeField]
  );

  const handleChangeValidator = useCallback(
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    (e: any) => {
      onChangeField(
        `validators`,
        {
          values: [e.value],
        },
        true
      );
    },
    [onChangeField]
  );

  const {options, validators} = field;

  const validatorValue = useMemo(
    () => field.options.find((o) => o.value === validators.values[0]) ?? null,
    [field.options, validators.values]
  );

  const optionInputErrors = useMemo(() => {
    return field.options
      .map(({label}, index) => (label === '' ? `選択肢${index + 1}が入力されていません` : null))
      .filter((msg): msg is string => msg !== null);
  }, [field.options]);

  return focused === true ? (
    <Grid container className={classes.editRoot} direction="column">
      <RadioGroup aria-label="radio">
        {options.map(({label}, index) => {
          const error = getIn(errors, `options[${index}].label`);
          return (
            <Grid key={index} container direction="column" className={classes.inputOptionContainer}>
              <Grid container className={classes.option}>
                <FormControlLabel
                  key={index}
                  className={classes.radioForm}
                  control={<Radio checked={false} />}
                  label={
                    <TextField
                      className={classes.radioLabel}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      value={label}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeOptionLabel(e, index)}
                    />
                  }
                />
                <IconButton className={classes.clearIcon} onClick={(e) => handleDeleteOption(e, index)}>
                  <Clear />
                </IconButton>
              </Grid>
              {focused === true && (
                <div className={classes.optionError}>
                  <FormError error={error} reserveSpace={true} />
                </div>
              )}
            </Grid>
          );
        })}
        <FormControlLabel
          className={classes.newOption}
          onClick={handleClickNewOption}
          control={<Radio checked={false} />}
          label={
            <Typography className={classes.newOptionLabel} variant="inherit">
              {placeholder}
            </Typography>
          }
        />
        {(optionInputErrors.length > 0 || errors._options) && (
          <Grid container direction="column" className={classes.summaryErrorContainer}>
            {optionInputErrors.map((message, index) => (
              <FormHelperText key={index} error={true}>
                {message}
              </FormHelperText>
            ))}
            <FormError error={errors._options} />
          </Grid>
        )}
        {field.settings.showsValidator && (
          <Grid container style={{marginLeft: '24px', marginTop: '16px'}}>
            <Grid item container alignItems="center">
              <Grid>エラー値の設定：次の値を含む</Grid>
              <Grid item style={{marginLeft: '16px', width: '150px'}}>
                <Select
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (provided) => ({...provided, zIndex: theme.zIndex.modal + 1}),
                  }}
                  name="validator.values[0]"
                  value={validatorValue}
                  options={field.options.filter((o) => !!o.label)}
                  onChange={handleChangeValidator}
                />
              </Grid>
            </Grid>
            <FormError error={errors?.validators?.values as string | undefined} />
          </Grid>
        )}
      </RadioGroup>
    </Grid>
  ) : (
    <Grid container direction="column">
      <RadioGroup aria-label="radio">
        {options.map((option, index) => (
          <FormControlLabel key={index} value={option.value} control={<Radio checked={false} />} label={option.label} />
        ))}
      </RadioGroup>
      {(optionInputErrors.length > 0 || errors._options || errors?.validators?.values) && (
        <Grid container direction="column" className={classes.summaryErrorContainer}>
          {optionInputErrors.map((message, index) => (
            <FormHelperText key={index} error={true}>
              {message}
            </FormHelperText>
          ))}
          <FormError error={errors._options} />
          <FormError error={errors?.validators?.values as string | undefined} />
        </Grid>
      )}
    </Grid>
  );
};
