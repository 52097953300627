import React, {useCallback} from 'react';
import {
  Popper,
  Paper,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  createStyles,
  makeStyles,
  Theme,
  styled,
} from '@material-ui/core';
import {MoreVert} from '@material-ui/icons';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {openSnackBar} from '@molecules/SnackBar';
import {FileListItemProps, FileElement} from '@molecules/MobileFileListItem/FileListItem';
import {EditFileNameDialog} from '@organisms/Files/FileList/EditFileNameDialog';
import {
  updateFile,
  deleteFile,
  UpdateFileParam,
  updateHospitalProductFile,
  UpdateHospitalProductFileParam,
} from '@modules/files/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';

/**
 * ファイルアクションポッパーボタン
 * ファイル名変更、機種共通ファイル登録、削除を行うボタン
 */

export const FileActionPopperBtn = (props: FileListItemProps) => {
  const {file, canDelete, isReadOnly, onEditFileName, onUpdateFile, onDeleteFile} = props;
  const {myInfo} = useMyInfo();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const clickButton = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const settingMenuItems = [
    {
      label: 'ファイル名を変更',
      value: 'rename',
    },
    {
      // NOTE:すでに登録済みなら解除
      label: `${file.isFileSharingAcrossProducts ? '機種共通ファイルから解除' : '機種共通ファイルとして登録'}`,
      value: 'wholeProduct',
    },
    ...(canDelete ? [{label: '削除', value: 'delete'}] : []),
  ];

  const handleMenuItemClick = (value: string) => {
    switch (value) {
      case 'rename':
        handleClickRename(file);
        break;

      case 'wholeProduct':
        handleClickShare(file);
        break;

      case 'delete':
        handleClickDelete(file);
        break;
    }
    setOpen(false);
  };

  /** リネームダイアログ表示およびリネーム処理   */
  const handleClickRename = useCallback(
    async (data: FileElement) => {
      const updatedFileName = await dialogHandler.open(EditFileNameDialog, {defaultFileName: data.fileName});

      try {
        await updateFile(data.hashId, updatedFileName as UpdateFileParam);

        openSnackBar('ファイルを更新しました。');
        onEditFileName(data.hashId);
      } catch (_e) {
        openSnackBar('ファイルの更新に失敗しました。', 'left', 'bottom', 'error');
      }
    },
    [onEditFileName]
  );

  /** 削除ダイアログ表示および削除処理 */
  const handleClickDelete = useCallback(
    async (data: FileElement) => {
      await dialogHandler.open(AlertDialog, {
        title: data.isFileSharingAcrossProducts ? '機種共通ファイルを削除しますか？' : 'ファイルを削除しますか？',
        content: data.isFileSharingAcrossProducts
          ? '同一機種に登録されている共通ファイルを削除しようとしています。\n\nこのアクションは元に戻せず、同一機種の他の機器に登録されている\nファイルも同時に削除されます。'
          : 'このファイルを削除しようとしています。\n\nこのアクションは元に戻せません。',
        positiveButtonLabel: 'ファイルを削除',
      });

      try {
        await deleteFile(data.hashId);

        openSnackBar('ファイルを削除しました。');
        onDeleteFile(data.hashId);
      } catch (_e: unknown) {
        openSnackBar('ファイルの削除に失敗しました。', 'left', 'bottom', 'error');
      }
    },
    [onDeleteFile]
  );

  /** ファイル共有処理 */
  const handleClickShare = useCallback(
    async (data: FileElement) => {
      try {
        if (!data.hospitalProductHashId) {
          openSnackBar('ファイルの共有に失敗しました。', 'left', 'bottom', 'error');
          return;
        }
        const updateFileParam: UpdateHospitalProductFileParam = {
          isFileSharingAcrossProducts: !data.isFileSharingAcrossProducts,
        };
        await updateHospitalProductFile(
          myInfo.hospitalHashId,
          data.hospitalProductHashId,
          data.hashId,
          updateFileParam
        );

        openSnackBar(
          data.isFileSharingAcrossProducts
            ? '機種共通ファイルから解除しました。'
            : '機種共通ファイルとして登録しました。'
        );

        if (onUpdateFile) onUpdateFile(data.hashId, !data.isFileSharingAcrossProducts);
      } catch (_e: unknown) {
        openSnackBar('ファイルの共有に失敗しました。', 'left', 'bottom', 'error');
      }
    },
    [myInfo.hospitalHashId, onUpdateFile]
  );

  if (isReadOnly) return null;
  return (
    <>
      <MoreVert ref={anchorRef} onClick={clickButton} />

      <Popper className={classes.popper} open={open} anchorEl={anchorRef.current} transition>
        {({TransitionProps}) => (
          <Grow {...TransitionProps} style={{transformOrigin: 'placement'}}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {settingMenuItems.map((item, index) =>
                    item.value === 'delete' ? (
                      <MenuItemDelete
                        key={`settingMenuItems${index}`}
                        onClick={() => {
                          handleMenuItemClick(item.value);
                        }}>
                        {item.label}
                      </MenuItemDelete>
                    ) : (
                      <MenuItem
                        key={`settingMenuItems${index}`}
                        onClick={() => {
                          handleMenuItemClick(item.value);
                        }}>
                        {item.label}
                      </MenuItem>
                    )
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const MenuItemDelete = styled(MenuItem)({
  color: '#C7243A',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      borderTop: `4px solid ${theme.palette.primary.dark}`,
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    leftContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    informationContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginLeft: '0px',
    },
    paperTitle: {
      fontWeight: 'bold',
      padding: '4px',
    },
    fullPaperSpace: {
      height: 'calc(100% - 36px)',
    },
    tooltip: {
      cursor: 'pointer',
      padding: '4px',
    },
    info: {
      cursor: 'pointer',
      padding: '10px 4px 0px 0px',
      width: '15px',
      height: '15px',
    },
    popper: {
      zIndex: 1000,
    },
    dragIcon: {
      height: '100%',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  })
);
