import {Box} from '@mui/material';
import React from 'react';

export const ProductThumbnail = () => {
  return (
    <Box
      component="img"
      sx={{
        width: '76px',
        height: '76px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        position: 'absolute',
        top: '12px',
        left: '32px',
      }}
      src="https://via.placeholder.com/76"
      alt="プロダクトサムネイル"
    />
  );
};
