import React, {useMemo} from 'react';
import {createStyles, Grid, makeStyles, Theme, FormHelperText} from '@material-ui/core';
import {ErrorTypeToFormError, FormError as FormErrorType} from './types';
import clsx from 'clsx';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    errorContainer: {
      marginTop: '4px',
    },
    errorContainerHeight: {
      height: '22px',
    },
    error: {
      marginTop: '0px',
    },
  })
);

type FormErrorProps = {
  error: string | undefined;
  reserveSpace?: boolean;
};

export const FormError = (props: FormErrorProps) => {
  const {error, reserveSpace} = props;
  const classes = useStyles();

  const errorText = useMemo(() => {
    if (error === undefined || !(error in ErrorTypeToFormError)) {
      return null;
    }

    return ErrorTypeToFormError[error as FormErrorType['type']];
  }, [error]);

  const rootClass =
    reserveSpace || errorText !== null
      ? clsx(classes.errorContainer, classes.errorContainerHeight)
      : clsx(classes.errorContainer);

  return (
    <Grid className={rootClass}>
      {errorText !== null && (
        <FormHelperText error={true} className={classes.error}>
          {errorText}
        </FormHelperText>
      )}
    </Grid>
  );
};
