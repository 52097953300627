import {InspectionType} from '@modules/inspections/enum';
import {yup} from '@front-libs/core';
import {EditWholeProductPlan} from './utils/reducer';

export const EDIT_MODE = 'edit';
export const VIEW_MODE = 'view';
export const ADD_MODE = 'add';
export const WAIT_MODE = 'wait'; // 待機モード(新規計画追加時、他の行は編集不可のため、待機モードにする)
export type ModeType = typeof EDIT_MODE | typeof VIEW_MODE | typeof ADD_MODE | typeof WAIT_MODE;

export type CreateWholeProductPlan = {
  uuid: string;
  inspectionSettingName: string; // 点検名
  inspectionName: string; // 点検表名
  inspectionHashId: string; // 点検表のHashId
  inspectionPeriod: number; // 点検間隔
  inspectionPeriodUnit: string; // 点検間隔の単位
  inspectionType: InspectionType | null;
};

const InspectionSettingSchema = yup.object().shape({
  inspectionSettingName: yup.string().required('点検名は必須です。'),
  inspectionType: yup.mixed().required('点検タイプは必須です。'),
  inspectionPeriod: yup
    .number()
    .nullable()
    .when('inspectionType', {
      is: (value: string) => value === 'periodic',
      then: yup
        .number()
        .required('点検タイプが定期点検の場合、点検間隔は必須です。')
        .min(1, '点検間隔は1ヶ月以上でなければなりません。')
        .max(120, '点検間隔は120ヶ月以下でなければなりません。'),
    }),
  inspectionHashId: yup.string().required('点検表は必須です。'),
});

export async function validateInspectionSetting(inspectionSetting: EditWholeProductPlan) {
  try {
    await InspectionSettingSchema.validate(inspectionSetting, {abortEarly: false});
    return true;
  } catch (_error) {
    return false;
  }
}
