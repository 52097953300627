import {useState, useEffect} from 'react';
import {useMediaQuery as useResponsive} from 'react-responsive';

const APPENDIX_WIDTH_BREAK_POINT = '1024px'; // ブレークポイントを定義
export const PRODUCT_PC_APPENDIX_WIDTH = '600px';
export const PRODUCT_TABLET_APPENDIX_WIDTH = '490px';
const APPENDIX_WIDTH_BREAK_POINT_NUMBER = 1024; // ブレークポイントを定義
export const PRODUCT_PC_APPENDIX_WIDTH_NUMBER = 600;
export const PRODUCT_TABLET_APPENDIX_WIDTH_NUMBER = 490;

export const useResponsiveLayout = () => {
  // タブレットかどうかを判定（画面幅1024px以下かどうか）
  const isTabletDisplay = useResponsive({
    query: `(max-width: ${APPENDIX_WIDTH_BREAK_POINT_NUMBER}px)`,
  });

  // 状態変数を用意して、画面幅を管理
  const [formWidth, setFormWidth] = useState<number>(0);

  // 右のAppendix部分の幅を決定
  const PRODUCT_DETAIL_FORM = isTabletDisplay ? PRODUCT_TABLET_APPENDIX_WIDTH_NUMBER : PRODUCT_PC_APPENDIX_WIDTH_NUMBER;

  // 初期ロード時と画面サイズ変更時にフォーム幅を更新
  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth - PRODUCT_DETAIL_FORM - 32;
      setFormWidth(currentWidth);
    };

    // 初回レンダリング後にフォーム幅をセット
    handleResize();

    // リサイズイベントを監視
    window.addEventListener('resize', handleResize);

    // コンポーネントがアンマウントされたときにリスナーを削除
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [PRODUCT_DETAIL_FORM]);

  return {
    isTabletDisplay,
    formWidth,
    PRODUCT_DETAIL_FORM,
  };
};
