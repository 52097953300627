import React from 'react';
import {CSSObject, Typography} from '@mui/material';
import {PageHeaderAlert} from '@molecules/PageHeaderAlert';

const FontSizeCss: CSSObject = {
  fontSize: '14px',
};
const HeadTextCss: CSSObject = {
  ...FontSizeCss,
  fontWeight: 700,
};

type Props = {onClose: VoidFunction};

/**
 *
 * 機種一覧ページにて機器登録ガイド文を表示する注意喚起ヘッダー
 *
 * @param props.onClose 閉じる処理
 * @returns
 */
export const ProductRegistrationCautionHeader: React.FC<Props> = ({onClose}) => {
  return (
    <PageHeaderAlert onClose={onClose} variant="warn">
      <Typography component={'h1'} sx={HeadTextCss}>
        機器を新規登録＞登録済みの院内機種から登録
      </Typography>
      <Typography component={'h1'} sx={HeadTextCss}>
        対象の機種にカーソルを合わせ、「機器を新規登録」ボタンをクリックしてください。
      </Typography>
    </PageHeaderAlert>
  );
};
