import React, {memo, useCallback, useRef, useState, useEffect} from 'react';
import {createStyles, Grid, makeStyles, IconButton, Theme, Card, Tooltip} from '@material-ui/core';
import {AddCircleOutline, ViewAgendaOutlined, Reorder} from '@material-ui/icons';
import {ArrayHelpers, FieldArray, FormikProps, getIn} from 'formik';
import clsx from 'clsx';
import {useAtom} from 'jotai';
import {useResetAtom} from 'jotai/utils';
import {focusedItemAtom, getDefaultField, getDefaultSection} from '@Apps/InspectionSettings/InspectionEditor/states';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {MessageDialog} from '@molecules/Dialogs/MessageDialog';
import {Section as SectionType, FormValue, Field} from './types';
import {Section} from '@Apps/InspectionSettings/InspectionEditor/Section';
import {
  ReorderSectionsDialog,
  ReorderSectionsDialogProps,
} from '@Apps/InspectionSettings/InspectionEditor/dialogs/ReorderSectionsDialog';
import {DragDropContext, DropResult} from 'react-beautiful-dnd';
import {useOnlyOnce} from '@front-libs/core';

const useSideActionBarStyle = makeStyles((theme: Theme) =>
  createStyles({
    /* Action Bar */
    root: {
      position: 'relative',
      marginLeft: '16px',
      padding: '20px 10px',
      width: '48px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: '10px',
      /* animation */
      top: '0px',
      transition: 'top 0.5s ease 0s',
      '& > button:not(:first-child)': {
        marginTop: '16px',
      },
      [theme.breakpoints.up('tabletH')]: {
        width: '60px',
      },
    },
    actionIconButton: {
      padding: '0px',
    },
    actionIcon: {
      width: '36px',
      height: '36px',
      [theme.breakpoints.up('tabletH')]: {
        width: '48px',
        height: '48px',
      },
    },
  })
);

type SideActionBarProps = {
  top: number;
  onClickAddItem: React.MouseEventHandler;
  onClickAddSection: React.MouseEventHandler;
  onClickOrderSections: React.MouseEventHandler;
};

const SideActionBar = memo((props: SideActionBarProps) => {
  const {top, onClickAddItem, onClickAddSection, onClickOrderSections} = props;
  const classes = useSideActionBarStyle();

  return (
    <Card elevation={2} className={classes.root} style={{top: `${top}px`}}>
      <Tooltip title="点検内容を追加" placement="right">
        <IconButton className={classes.actionIconButton} onClick={onClickAddItem}>
          <AddCircleOutline className={classes.actionIcon} />
        </IconButton>
      </Tooltip>
      <Tooltip title="点検項目を追加" placement="right">
        <IconButton className={classes.actionIconButton} onClick={onClickAddSection}>
          <ViewAgendaOutlined fontSize="large" className={classes.actionIcon} />
        </IconButton>
      </Tooltip>
      <Tooltip title="点検項目の並び替え" placement="right">
        <IconButton className={classes.actionIconButton} onClick={onClickOrderSections}>
          <Reorder fontSize="large" className={classes.actionIcon} />
        </IconButton>
      </Tooltip>
    </Card>
  );
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemRow: {
      justifyContent: 'center',
    },
    itemColumn: {
      flex: 1,
      position: 'relative',
    },
    itemColumnMain: {
      minWidth: '596px',
      flex: '0 0 50%',
      '& > div:not(:first-child)': {
        marginTop: '16px',
      },
      [theme.breakpoints.up('tabletH')]: {
        minWidth: '700px',
      },
    },
    item: {
      padding: '16px 24px 16px 30px',
    },
    itemFocused: {
      padding: '16px 24px 16px 24px',
      borderLeftWidth: '6px',
      borderLeftStyle: 'solid',
      borderLeftColor: theme.palette.primary.main,
    },
  })
);

type ItemsFormInnerProps = ArrayHelpers & {
  actionBarTop: number;
  onItemUnfocused: () => void;
  form: FormikProps<FormValue>;
  name: string;
};

const ItemsFormInner = (props: ItemsFormInnerProps) => {
  const {actionBarTop, onItemUnfocused, form, insert, remove} = props;
  const classes = useStyles();
  const [focusedItem, setFocusedItem] = useAtom(focusedItemAtom);
  const formValueRef = useRef<FormValue>(form.values);

  useEffect(() => {
    formValueRef.current = form.values;
  }, [form.values]);

  const handleClickDeleteSection = useCallback(
    async (_: React.MouseEvent, index: number) => {
      try {
        await dialogHandler.open(MessageDialog, {
          title: '点検項目と点検内容を削除しますか？',
          content: `点検項目を削除すると、点検項目に含まれている点検内容も削除されます。`,
          positiveButtonLabel: '削除',
          warning: true,
        });
      } catch (_e) {
        return;
      }
      remove(index);
      onItemUnfocused();
    },
    [onItemUnfocused, remove]
  );

  const handleClickAddSection = useCallback(
    (_e: React.MouseEvent) => {
      const {sectionIndex} = focusedItem;

      const section = getIn(formValueRef.current, `sections[${sectionIndex}]`) as SectionType;
      if (!section) {
        // make sure section is not nullish
        return;
      }

      // insert empty section
      insert(sectionIndex + 1, getDefaultSection());
      setFocusedItem({sectionIndex: sectionIndex + 1, fieldIndex: null, el: null});
    },
    [focusedItem, insert, setFocusedItem]
  );

  const handleClickDuplicateSection = useCallback(
    (_: React.MouseEvent, index: number) => {
      const sections = getIn(formValueRef.current, 'sections') as SectionType[];
      const duplicatedSection = JSON.parse(JSON.stringify(sections[index])) as SectionType;

      duplicatedSection.id = '';
      duplicatedSection.fields.forEach((field: Field) => {
        field.id = '';
      });

      insert(index + 1, duplicatedSection);
      setFocusedItem({sectionIndex: index + 1, fieldIndex: null, el: null});
    },
    [insert, setFocusedItem]
  );

  const handleClickAddItem = useCallback(
    // eslint-disable-next-line no-shadow
    (_e: React.MouseEvent) => {
      const {sectionIndex} = focusedItem;
      const fieldIndex = focusedItem.fieldIndex !== null ? focusedItem.fieldIndex : 0;
      const prevSection = getIn(formValueRef.current, `sections[${sectionIndex}]`);

      if (prevSection !== undefined) {
        const newSection = {
          id: prevSection.id,
          name: prevSection.name,
          fields: [
            ...prevSection.fields.slice(0, fieldIndex + 1),
            getDefaultField(),
            ...prevSection.fields.slice(fieldIndex + 1),
          ],
        };
        form.setFieldValue(`sections[${sectionIndex}]`, newSection);
      }
      setFocusedItem({sectionIndex, fieldIndex: fieldIndex + 1, el: null});
    },
    [focusedItem, form, setFocusedItem]
  );

  const handleClickOrderSections = useCallback(
    // eslint-disable-next-line no-shadow
    async (_e: React.MouseEvent) => {
      const sections = getIn(formValueRef.current, 'sections') as SectionType[];
      if (sections.length === 0) return;
      try {
        const reorderedSections = await dialogHandler.open<ReorderSectionsDialogProps, SectionType[]>(
          ReorderSectionsDialog,
          {
            sections: sections,
          }
        );
        form.setFieldValue('sections', [...reorderedSections]);
      } catch (_) {
        return;
      }
    },
    [form]
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const {source, destination} = result;
      if (destination === undefined) return;

      const {section: sourceSectionIndex}: {section: number} = JSON.parse(source.droppableId);
      const sourceFieldIndex = source.index;
      const {section: destinationSectionIndex}: {section: number} = JSON.parse(destination?.droppableId ?? '');
      const destinationFieldIndex = destination?.index ?? -1;

      if (sourceSectionIndex === destinationSectionIndex && sourceFieldIndex === destinationFieldIndex) return;

      const sections = getIn(formValueRef.current, 'sections') as SectionType[];
      if (sourceSectionIndex !== destinationSectionIndex) {
        const sourceFields = [...sections[sourceSectionIndex].fields];
        const targetField = {...sourceFields[sourceFieldIndex]};
        const destinationFields = [...sections[destinationSectionIndex].fields];

        const newSections = [...sections];
        newSections[destinationSectionIndex] = {
          ...newSections[destinationSectionIndex],
          fields: [
            ...destinationFields.slice(0, destinationFieldIndex),
            targetField,
            ...destinationFields.slice(destinationFieldIndex),
          ],
        };

        newSections[sourceSectionIndex] = {
          ...newSections[sourceSectionIndex],
          fields: [...sourceFields.slice(0, sourceFieldIndex), ...sourceFields.slice(sourceFieldIndex + 1)],
        };

        form.setFieldValue('sections', newSections, true);
      } else {
        // 同じセクション内部での移動 (フィールドのシフトが発生する)
        const originalFields = sections[sourceSectionIndex].fields ?? [];
        if (sourceFieldIndex > destinationFieldIndex) {
          // 下から上へ
          form.setFieldValue(
            `sections[${sourceSectionIndex}].fields`,
            [
              ...originalFields.slice(0, destinationFieldIndex), // 挿入先より上
              originalFields[sourceFieldIndex], // 移動したフィールド
              ...originalFields.slice(destinationFieldIndex, sourceFieldIndex), // 挿入先から挿入元の間
              ...originalFields.slice(sourceFieldIndex + 1), // 挿入元より下
            ],
            true
          );
        } else {
          // 上から下へ
          form.setFieldValue(
            `sections[${sourceSectionIndex}].fields`,
            [
              ...originalFields.slice(0, sourceFieldIndex), // 挿入元より上
              ...originalFields.slice(sourceFieldIndex + 1, destinationFieldIndex + 1), // 挿入元から挿入先の間
              originalFields[sourceFieldIndex], // 移動したフィールド
              ...originalFields.slice(destinationFieldIndex + 1), // 挿入先より下
            ],
            true
          );
        }
      }
    },
    [form]
  );

  return (
    <Grid container justifyContent="center">
      <Grid container className={classes.itemRow}>
        {/* Fake */}
        <Grid className={classes.itemColumn} />
        {/* Sections */}
        <Grid container direction="column" className={clsx(classes.itemColumn, classes.itemColumnMain)}>
          <DragDropContext onDragEnd={onDragEnd}>
            {form.values.sections.map((_section, index) => (
              <Section
                key={index}
                index={index}
                onClickDuplicateSection={(e) => handleClickDuplicateSection(e, index)}
                onClickDeleteSection={(e) => handleClickDeleteSection(e, index)}
              />
            ))}
          </DragDropContext>
        </Grid>
        {/* Action menus */}
        <Grid className={classes.itemColumn}>
          <SideActionBar
            top={actionBarTop}
            onClickAddItem={handleClickAddItem}
            onClickAddSection={handleClickAddSection}
            onClickOrderSections={handleClickOrderSections}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const ItemsForm = () => {
  const [focusedItem, setFocusedItem] = useAtom(focusedItemAtom);
  const resetFocusedItem = useResetAtom(focusedItemAtom);
  const [actionBarTop, setActionBarTop] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useOnlyOnce(() => resetFocusedItem());

  useEffect(() => {
    if (containerRef.current !== null && focusedItem.el !== null) {
      const {top: containerTop} = containerRef.current.getBoundingClientRect();
      const {top: itemTop} = focusedItem.el.getBoundingClientRect();
      setActionBarTop(Math.max(0, itemTop - containerTop));
    } else {
      setActionBarTop(0);
    }
  }, [focusedItem]);

  const handleItemUnfocused = useCallback(() => {
    setFocusedItem((prev) => ({
      el: prev.el,
      sectionIndex: Math.max(0, prev.sectionIndex - 1),
      fieldIndex: null,
    }));
  }, [setFocusedItem]);

  return (
    <Grid ref={containerRef}>
      <FieldArray
        name="sections"
        render={(arrayHelpers) => (
          <ItemsFormInner {...arrayHelpers} actionBarTop={actionBarTop} onItemUnfocused={handleItemUnfocused} />
        )}
      />
    </Grid>
  );
};
