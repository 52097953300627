import React, {useCallback} from 'react';
import {Button as MButton, styled, Paper, Typography} from '@material-ui/core';
import {ExcelFileDropZone} from '@organisms/HospitalProductImporter';
import {useBulkUpdateHospitalProductsExcel} from './hooks';
import {exportTemplateHospitalProducts} from '@modules/hospital_products/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {openSnackBar} from '@molecules/SnackBar';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {useNavigate} from 'react-router-dom';
import {Notifications} from '@material-ui/icons';
import {HospitalProductTable} from './HospitalProductTable';

/* 
〜〜HospitalProductsをインポートできるまでの遠い道のり〜
1. 更新用のExcelファイルのダウンロード

*/
export const ProductBulkUpdates: React.FC = () => {
  const {myInfo} = useMyInfo();
  const navigate = useNavigate();
  const handleClickReturnProductList = useCallback(() => navigate('/products'), [navigate]);
  const handleDownloadFile = async () => {
    try {
      await exportTemplateHospitalProducts(myInfo.hospitalHashId);
      openSnackBar('機器台帳一括更新用のテンプレートのダウンロードを開始しました。', 'left', 'bottom');
    } catch (_e) {
      openSnackBar('機器台帳一括更新用のテンプレートのダウンロードに失敗しました。', 'left', 'bottom', 'error');
    }
  };

  const {
    uploadFile,
    hospitalProducts,
    displayHospitalProducts,
    hospitalProductsPage,
    isValidFile,
    submitHospitalProductData,
    setHospitalProductsPage,
    noteData,
    isLoadingNoteSettings,
  } = useBulkUpdateHospitalProductsExcel();

  return (
    <BasePaper>
      <Section>
        <PageHeader>機器情報の一括更新</PageHeader>
        <BodyText>所定のテンプレート（Excelファイル）を用いて、機器情報を一括更新できます。</BodyText>
      </Section>
      <Section>
        <SectionHeader>1. Excelファイルのダウンロード</SectionHeader>
        <BodyText>
          下記ボタンをクリックして、所定のテンプレート（Excelファイル）をダウンロードしてください。
          <br />
          <div style={{display: 'flex', alignItems: 'center'}}>
            ダウンロードが完了すると、
            <span style={{fontWeight: 'bold', display: 'inline-flex', alignItems: 'center'}}>
              <Notifications />
              に通知が届きます。
            </span>
          </div>
        </BodyText>
        <Button color="primary" variant="contained" onClick={handleDownloadFile}>
          機器情報更新用のテンプレートをダウンロード
        </Button>
      </Section>
      <Section>
        <SectionHeader>2. Excelファイルの準備</SectionHeader>
        <BodyText>
          1でダウンロードしたExcelファイルには、ダウンロード時点での機器情報が全量記載されています。
          <br />
          更新する行（機器）のA列に〇をつけ、機器情報（I列～AQ列）を編集してください。
          <br />
          選択した行の機器情報はすべてExcelファイルの情報で上書きされます。Excelファイルのダウンロード後、一括更新までの間にHITOTSU
          Asset上で機器情報を編集した場合、Excelファイルの情報で上書きされますのでご注意ください。
        </BodyText>
      </Section>
      <Section>
        <SectionHeader>3. Excelファイルのアップロード</SectionHeader>
        <BodyText>
          2で準備したExcelファイルをアップロードしてください。
          <br />
          ※ExcelファイルA列に「◯」が入力されている行のみ更新します。
        </BodyText>
        <ContentWrapper>
          <ExcelFileDropZone onUpload={uploadFile} />
        </ContentWrapper>
      </Section>
      {isValidFile && (
        <>
          <Section>
            <SectionHeader>4. データの確認・更新</SectionHeader>
            <BodyText>
              アップロードされたExcelファイルを基に、更新する機器台帳データを表示します。
              <br />
              確認後、問題なければ「データを更新」ボタンをクリックしてください。修正する場合はExcelファイルを修正し、再度アップロードしてください。
              <br />
              ※Excelファイルのダウンロード後、一括更新までの間にHITOTSU
              Asset上で機器情報を編集した場合、Excelファイルの情報で上書きされますのでご注意ください。
            </BodyText>
            <ContentWrapper>
              <HospitalProductTable
                hospitalProducts={displayHospitalProducts}
                page={hospitalProductsPage + 1}
                totalCount={hospitalProducts.length}
                onPageChange={(newPage: number) => {
                  setHospitalProductsPage(newPage - 1);
                }}
                noteData={noteData}
                isLoading={isLoadingNoteSettings}
              />
            </ContentWrapper>
          </Section>
          <Section>
            <Button
              disabled={!isValidFile || hospitalProducts.length === 0}
              color="primary"
              variant="contained"
              onClick={submitHospitalProductData}>
              データを更新
            </Button>
          </Section>
        </>
      )}
      <Button variant="outlined" color="primary" onClick={handleClickReturnProductList}>
        <ChevronLeftIcon />
        機器一覧画面に戻る
      </Button>
    </BasePaper>
  );
};

const Section = styled('section')({
  marginBottom: 32,
});

const ContentWrapper = styled('div')({
  margin: '16px auto',
});

const Button = styled(MButton)({
  width: 'aut',
  marginTop: 8,
});

const BasePaper = styled(Paper)({
  backgroundColor: 'white',
  width: '100%',
  paddingLeft: '16px',
  paddingTop: '16px',
  paddingBottom: '16px',
  overflowY: 'auto',
});

const PageHeader: React.FC = ({children}) => {
  return <StyledHeader variant="h5">{children}</StyledHeader>;
};

const BodyText: React.FC = ({children}) => {
  return <StyledBody variant="body1">{children}</StyledBody>;
};

const SectionHeader = styled(Typography)(({theme}) => ({
  color: theme.palette.common.black,
  fontSize: 18,
  fontWeight: 700,
  marginBottom: 16,
}));

const StyledHeader = styled(Typography)(({theme}) => ({
  color: theme.palette.common.black,
  marginBottom: 24,
}));

const StyledBody = styled(Typography)(({theme}) => ({
  color: theme.palette.common.black,
  fontSize: 14,
}));
